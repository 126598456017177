
import {

    Navigate
} from 'react-router-dom'

const ProtectedRoute = ({
    children
}) => {
    const AUTH_TOKEN = sessionStorage.getItem('logToken')
    return (
        AUTH_TOKEN ? children : <Navigate
            to={{
                pathname: "/",
            }}
        />
    )
}

export default ProtectedRoute; 
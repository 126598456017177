import { useState, useEffect, useContext } from 'react'
import NavBar from '../components/Navbar'
import Navpills from '../components/Navpills'
import Counter from '../components/Counter'
import GameTable from '../components/GameTable'
import UserResults from '../components/UserResults'
import AllResults from '../components/AllResults'
import ColorModal from '../components/ColorModal'
import NumberModal from '../components/NumberModal'
import { CounterContext } from '../service/StateContext'

const Platinum = () => {

  const gameTable = "PLATINUM"
  const [showModal, setShowModal] = useState(false)
  const [showModalNumber, setShowModalNumber] = useState(false)
  const root = "/session-platinum"
  const state:any = useContext(CounterContext)
  let checkCountDown = state.counter == "0:01"
  console.log(checkCountDown,'checkCountDown')
  useEffect(()=>{
    const model:any = localStorage?.getItem("model")
    if(model===true){
      setShowModal(false);
      setShowModalNumber(false);
    }
  },[NumberModal,ColorModal])

  useEffect(() => {
    if (checkCountDown) {
      window.location.reload();
    }
  }, [checkCountDown])

  return (
    <>
      <div className="row app">
        <div className="col sm-12 px-0">
          <div className="app--main">
            <Navpills />
            <div className="container-sm">
              <div className="tab-content" id="gift-eventContent">
                  <div className="row">
                    <div className="col-md-6">
                      <Counter />
                      <GameTable setShowModal={setShowModal} setShowModalNumber={setShowModalNumber} />
                      <UserResults gameTable={gameTable} />
                    </div>
                    <div className="col-md-6">
                      <AllResults gameTable={gameTable} />
                    </div>
                  </div>
                <NavBar />
                <ColorModal root={root} showModal={showModal} setShowModal={setShowModal} gameTable={gameTable} />
                <NumberModal
                  root={root}
                  showModalNumber={showModalNumber}
                  setShowModalNumber={setShowModalNumber}
                  gameTable={gameTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Platinum

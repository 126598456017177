import { useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import useColorGame from '../hooks/useColorGame';
import { StateContext } from '../service/StateContext';

const ColorModal = ({
  root,
  showModal,
  setShowModal,
  gameTable
}) => {
  const [amount, setAmount] = useState(1)
  const [quantity, setQuantity] = useState(1)
  const { state } = useContext(StateContext)
  const { joinGame } = useColorGame(gameTable, amount * quantity,root )

  const close = () => {
    setShowModal(false)
    setAmount(1)
    setQuantity(1)
  }

  const handleChange = (event) => {
    const val = event.target.value
    setQuantity(val)
  }

  const sendHundred = (id) => {
    setAmount(id)
  }

  const IncrementItem = () => {
    setQuantity(quantity + 1)
  }

  const DecreaseItem = () => {
    if (quantity < 1) {
      setQuantity(0)
    } else {
      setQuantity(quantity - 1)
    }
  }

  return (
    <Modal show={showModal} onHide={close}>
      {localStorage.getItem('clr') === 'green' ? (
        <Modal.Header closeButton style={{ background: '#27C04C' }}>
          <Modal.Title>
            <div>
              <h5 style={{ color: 'white' }}>Join Color Game - Green</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
      ) : (
        <div style={{ display: 'none' }}>null</div>
      )}
      {localStorage.getItem('clr') === 'red' ? (
        <Modal.Header closeButton style={{ background: '#F84450' }}>
          <Modal.Title>
            <div>
              <h5 style={{ color: 'white' }}>Join Color Game - Red</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
      ) : (
        <div style={{ display: 'none' }}>null</div>
      )}
      {localStorage.getItem('clr') === 'purple' ? (
        <Modal.Header closeButton style={{ background: '#7E39E0' }}>
          <Modal.Title>
            <div>
              <h5 style={{ color: 'white' }}>Join Color Game - Purple</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
      ) : (
        <div style={{ display: 'none' }}>null</div>
      )}
      <Modal.Body>
        <div className="align">
          <div className="tronText" style={{ marginTop: '10px' }}>
            Tron
          </div>
          <div className="main_next">
            <input
              className="moneyGame pay-option-check"
              value="1"
              onClick={() => {
                sendHundred(1)
              }}
              readOnly
            />
            <input
              className="moneyGame pay-option-check"
              value="10"
              onClick={() => {
                sendHundred(10)
              }}
              readOnly
            />
            <input
              className="moneyGame pay-option-check"
              value="100"
              onClick={() => {
                sendHundred(100)
              }}
              readOnly
            />
            <input
              className="moneyGame pay-option-check"
              value="1K"
              onClick={() => {
                sendHundred(1000)
              }}
              readOnly
            />
            <input
              className="moneyGame pay-option-check"
              value="10k"
              onClick={() => {
                sendHundred(10000)
              }}
              readOnly
            />
            <input
              className="moneyGame pay-option-check"
              value="100K"
              onClick={() => {
                sendHundred(100000)
              }}
              readOnly
            />
            <input
              className="moneyGame pay-option-check"
              value="1M"
              onClick={() => {
                sendHundred(1000000)
              }}
              readOnly
            />
          </div>
        </div>
        <div className="align">
          <div className="tronText">Number</div>
          <div className="main_nextID">
            <button onClick={DecreaseItem} className={quantity == 1 ? "btnID disabledMinus" : "btnID"}>
              -
            </button>
            <input
              className="inputne"
              type="number"
              name="quantity"
              value={Number(quantity).toString()}
              onChange={handleChange}
            />
            <button onClick={IncrementItem} className="btnI">
              +
            </button>
          </div>
        </div>
        <div className="align">
          <div className="tronText">Total Tron coins</div>
          <div className="amountValue">
            {quantity * amount}
            {quantity * amount > state.balance ? (
              <div style={{ color: 'red', fontSize: '12px' }}>*Not enough state.balance</div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-btn">
        <button className="btn-block" onClick={close}>
          Cancel
        </button>
        {quantity * amount > state.balance ? (
          <button 
            style={{ marginTop: '2px' }} 
            className="btn-block" 
            disabled
          >
            Confirm
          </button>
        ) : (
          <button
            style={{ marginTop: '2px' }}
            className="btn-block"
            onClick={joinGame }
          >
            Confirm
          </button>
        )}
      </Modal.Footer>
    </Modal>

  )
  
}

export default ColorModal
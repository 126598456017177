import { NavLink, Outlet } from 'react-router-dom'

const Navpills = () => {
  return(
    <>
    <ul className="nav nav-pills mb-3" id="gift-event" role="tablist">
      <li className="nav-item" role="presentation">
        <NavLink
          className="nav-link "
          to="/session-gold"
        >
          Gold
        </NavLink>
      </li>
      <li className="nav-item" role="presentation">
        <NavLink
          className="nav-link"
          to="/session-platinum"
        >
          Platinum
        </NavLink>
      </li>
      <li className="nav-item" role="presentation">
        <NavLink
          className="nav-link"          
          to="/session-diamond"
        >
          Diamond
        </NavLink>
      </li>
      <li className="nav-item" role="presentation">
        <NavLink
          className="nav-link"

          to="/session-palladium"
        >
          Palladium
        </NavLink>
      </li>
    </ul>
    <Outlet />
    </>
  )
}

export default Navpills
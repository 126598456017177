import { useState, useEffect, useContext } from 'react'
import useGetResults from '../hooks/useGetResults'
import { CounterContext, ResultContext } from '../service/StateContext'

const AllResults = ({
  gameTable
}) => {  
  const { state } = useContext(ResultContext)
  const { state: counterState } = useContext(CounterContext)
  const { getAllResults } = useGetResults()
  const [page, setPage] = useState(1)

  useEffect(() => {
    getAllResults(1)
  },[getAllResults])

  useEffect(() => {
    if (counterState.counter === '2:58') {
      getAllResults(1)
    }
  }, [counterState.counter, getAllResults])
  
  useEffect(() => {
    getAllResults(page)
  }, [page, getAllResults])

  const nextHandler = async () => {
    setPage(page + 1)
  }

  const prevHandler = async () => {
    setPage(page - 1)
  }

  return (
      <section className="gold-record">
        <h4 className="text-center">{gameTable.replace(/^!\w?/g, res => res.toLowerCase())} Record</h4>
        <div className="table-responsive resultTable">
          <table className="table" id="Example">
            <thead>
              <tr>
                <th>Period</th>
                <th>Price</th>
                <th>Number</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {state.data === undefined || state.data === null || state.data.length <= 0
                ?
                <tr>
                  <td>No Records Available</td>
                </tr>
                :
                state.data.map((item) => {
                  return (
                    <tr>
                      {item.gameName === gameTable.toLowerCase() ? (
                        <td>
                          {item.gameName === gameTable.toLowerCase() ? (
                            <div>{item.period}</div>
                          ) : (
                            <div style={{ display: 'none' }}>null</div>
                          )}
                        </td>
                      ) : (
                        <div style={{ display: 'none' }}>null</div>
                      )}
                      {item.gameName === gameTable.toLowerCase() ? (
                        <td>
                          {item.gameName === gameTable.toLowerCase() ? (
                            <div>{item.price}</div>
                          ) : (
                            <div style={{ display: 'none' }}>null</div>
                          )}
                        </td>
                      ) : (
                        <div style={{ display: 'none' }}>null</div>
                      )}
                      {item.gameName === gameTable.toLowerCase() ? (
                        <td>
                          {item.gameName === gameTable.toLowerCase() ? (
                            <p className="result-number text-violet">
                              {' '}
                              <div>{item.numberWinner}</div>
                            </p>
                          ) : (
                            <div style={{ display: 'none' }}>null</div>
                          )}
                        </td>
                      ) : (
                        <div style={{ display: 'none' }}>null</div>
                      )}
                      {item.gameName === gameTable.toLowerCase() ? (
                        <td>
                          {item.colourWinner === 'red' && item.gameName === gameTable.toLowerCase() ? (
                            <div className="result-circle btn-red"></div>
                          ) : (
                            <div style={{ display: 'none' }}>null</div>
                          )}

                          {item.colourWinner === 'green' && item.gameName === gameTable.toLowerCase() ? (
                            <div className="result-circle btn-green"></div>
                          ) : (
                            <div style={{ display: 'none' }}>null</div>
                          )}
                          {item.colourWinner === 'purple' && item.gameName === gameTable.toLowerCase() ? (
                            <div className="result-circle btn-violet"></div>
                          ) : (
                            <div style={{ display: 'none' }}>null</div>
                          )}
                        </td>
                      ) : (
                        <div style={{ display: 'none' }}>null</div>
                      )}
                    </tr>
                  )
                })}
            </tbody>
          </table>
          {Number.isNaN((state.pageData.limit - 30) * state.pageData.prevPage + 1) ?
            (
              <></>
            ) : (
              <div className="">
                <div className="prev-next">
                  <div className="total-page">
                    {((state.pageData.limit - 30) * state.pageData.prevPage + 1).toString()} -
                    {((state.pageData.limit - 30) * state.pageData.page).toString()}
                  </div>
                  <div className="total-page">
                    {Math.round(state.pageData.totalDocs / 4).toString()}
                  </div>
                  <div>
                    {state.pageData.hasPrevPage === false ? (
                      <i
                        className="fa fa-angle-left"
                        aria-hidden="true"
                        disabled
                        style={{ fontSize: '22px', color: '#cec7c7' }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-angle-left"
                        aria-hidden="true"
                        onClick={prevHandler}
                        style={{ fontSize: '22px', cursor: 'pointer' }}
                      ></i>
                    )}
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div>
                    {state.pageData.hasNextPage === true ? (
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="true"
                        onClick={nextHandler}
                        style={{ fontSize: '22px', cursor: 'pointer' }}
                      >
                        {' '}
                      </i>
                    ) : (
                      <i
                        className="fa fa-angle-right"
                        aria-hidden="false"
                        disabled
                        style={{ fontSize: '22px', color: '#cec7c7' }}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      </section>
  )
}

export default AllResults
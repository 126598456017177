import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { urlNetwork } from '../config'

import useLogin from '../hooks/useLogin'
import { StateContext } from '../service/StateContext'

const Login = () => {

  const { state } = useContext(StateContext)
  const navigate = useNavigate()
  const { login } = useLogin()
  const register = () => {
    navigate('/register')
  }

  // useEffect(() => {
  //   console.log(state.isLoggedIn)
  //   if(state.isLoggedIn){
  //     let url = urlNetwork
  //     const response = fetch(url, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //       },
  //     })
  //     const userResult = await response.json()
  //     const data = userResult
  //     console.log(data,'data')
  //   }
  // },[state.isLoggedIn])

  return (
    <div>
      <div className="container pt-5" style={{ marginBottom: 106 }}>
        <div className="row">
          <div className="col-sm-12">
            <div className="border-form-login">
              <div className="btn-main-login">
                { state.isLoading ?
                  <>
                  <button className="btn-block" onClick={login} disabled>Authorisation</button>
                  <div style={{ padding: "15px" }}> </div>
                  <button className="btn-block" onClick={register} disabled>Register</button>
                  </>
                  :
                  <>
                  <button className="btn-block" onClick={login} >Authorisation</button>
                  <div style={{ padding: "15px" }}> </div>
                  <button className="btn-block" onClick={register}>Register</button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
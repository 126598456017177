import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import { GiftEventContext } from '../service/StateContext'
import useGiftEvent from '../hooks/useGiftEvent'

const GiftEvent = () => {
  const [showModalEvent, setShowModalEvent] = useState(false)
  const [showModalFail, setShowModalFail] = useState(false)
  const [startTimeEvent, setStartTimeEvent] = useState('')
  const [endTimeEvent, setEndTimeEvent] = useState('')

  const navigate = useNavigate()
  const { getGiftEvent } = useGiftEvent()
  const { state } = useContext(GiftEventContext)

  useEffect(() => {
    getGiftEvent()
  }, [])

  const OpenEvent = (startTimeEvent, endTimeEvent) => {
    setShowModalEvent(true)
    setStartTimeEvent(startTimeEvent)
    setEndTimeEvent(endTimeEvent)
  }

  const closeFail = () => {
    setShowModalFail(false)
  }

  const closeEvent = () => {
    setShowModalEvent(false)
    setShowModalFail(true)
  }

  const depositeHandler = () => {
    navigate('/deposite')
  }

  var CurrentDate = moment().format('hh:mm:ss')
  console.log(CurrentDate)
  const CustomMaont = (startime, endtime) => {
    console.log(startime)
    console.log(endtime)
    let sTime = moment(startime * 1000).format('HH:mm')
    let eTime = moment(endtime * 1000).format('HH:mm')
    let startBefore = moment(sTime).isBefore(CurrentDate)
    console.log(startBefore)
    let endAfter = moment(eTime).isAfter(CurrentDate)
    console.log(endAfter)
    return (
      <div style={{ display: 'flex' }}>
        <div>{sTime}</div>~<div>{eTime}</div>
      </div>
    )
  }
  return (
    <main>
      <div style={{ marginBottom: 106 }}>
        <div class="row">
          <div class="col-sm-12">
            <div className="giftFirstText">
              Gift Events: Gift Events are randomly distributed, and if the basic conditions are met , you can
              participate. The events are updated by admin, and for each round gift amount is distributed among the
              first members who complete the event .
            </div>

            <div className="row app">
              <div className="col sm-12 px-0 ">
                <div className="table-responsive gold-record-table" style={{ marginTop: '20px' }}>
                  <table className="table">
                    <thead className="tran-head">
                      <th>Time</th>
                      <th>Requirements</th>
                      <th>Maximum Winners</th>
                      <th>Gift Price</th>
                      <th>ID</th>
                    </thead>
                    <tbody>
                      {state.giftEvent.map((item, i) => {
                        return (
                          <tr
                            onClick={() => {
                              OpenEvent(item.startTime, item.endTime, item.timestamp)
                            }}
                          >
                            <td>{CustomMaont(item.startTime, item.endTime)}</td>
                            <td>
                              Add Tron = {item.minimumRecharge} and Join Game = {item.minimumGameJoin}
                            </td>
                            <td>{item.numberOfWinners}</td>
                            <td>{item.giftPrice}</td>
                            <td>{item.id}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalEvent} onHide={closeEvent}>
        <Modal.Body style={{ padding: '30px' }}>
          <div className="">
            <h5>Event Details</h5>
            event started at,
            {CustomMaont(startTimeEvent, endTimeEvent)}
            Open gift can obtain random tron to your wallet
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          <button className="btn-block" onClick={depositeHandler}>
            OPEN GIFT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalFail} onHide={closeFail}>
        <Modal.Body style={{ padding: '30px' }}>
          <div className="">
            <h5>Fail</h5>
            Event not yet started please try later
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-btn">
          <button className="btn-block" onClick={closeFail}>
            CLOSE
          </button>
        </Modal.Footer>
      </Modal>
    </main>
  )
}

export default GiftEvent

import { useState, useEffect, useContext } from 'react'
import NavBar from '../components/Navbar'
import useGetTransactions from '../hooks/useGetTransactions'
import { TransactionsContext } from '../service/StateContext'

const UserTransactions = () => {
  const [timeStamp, setTimeStamp] = useState(new Date())

  const { getTransactions } = useGetTransactions()
  const { state } = useContext(TransactionsContext)

  useEffect(() => {
    getTransactions()
  }, [])

  return (
    <div>
      <main>
        <div className="row app">
          <div className="col sm-12 px-0">
            <div className="table-responsive gold-record-table">
              <table className="table">
                <thead className="tran-head">
                  <th>Amount</th>
                  <th>Game Name</th>
                  <th>Period</th>
                  <th>Order ID</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {console.log(
                    new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    }).format(timeStamp)
                  )}

                  {state.transactions === undefined || state.transactions === null || state.transactions.length <= 0
                    ? null
                    : state.transactions.map((item) => {
                      return (
                        <tr>
                          <td>{item.amount}</td>
                          <td>{item.gameName}</td>
                          <td>{item.period}</td>
                          <td>{item.orderId}</td>
                          <td>{item.status}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <NavBar />
      </main>
    </div>
  )
}

export default UserTransactions
import { useEffect } from 'react'
import { 
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'
import { 
  NotificationsProvider, notify, 
} from 'reapop'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import Header from './components/Header'

import {
  CounterContextProvider,   
  GameContextProvider, 
  GiftEventProvider, 
  ResultContextProvider, 
  StateContextProvider, 
  TransactionsContextProvider 
} from './service/StateContext'

import Login from './pages/Login'
import Register from './pages/Register'
import Gold from './pages/Gold'
import Diamond from './pages/Diamond'
import Platinum from './pages/Platinum';
import Palladium from './pages/Palladium';
import Withdraw from './pages/Withdraw'
import Deposite from './pages/Deposite'
import Transactions from './pages/Transactions'
import UserTransactions from './pages/UserTransactions'
import Home from './pages/Home'
import UserMenu from './pages/UserMenu'
import Promotions from './pages/Promotions'
import GiftEvent from './pages/GiftEvent'
import Userpass from './pages/Userpass'
import ResetPin from './pages/ResetPin'
import ProtectedRoute from './components/ProtectedRoute'



declare global {
  interface Window {
    tronWeb: any
  }
}

function App() {
  let getToken = sessionStorage.getItem("logToken");
  const chainConnect = window?.tronWeb?.fullNode?.host
  const window_location = window.location.href;
  let checkReference = window_location.includes("/#/invite?referal=")
  let getReflink = localStorage.getItem("referLink")?.split('=')?.[1]
  const metaMaskAddress = window?.tronWeb?.defaultAddress?.base58
  let address = localStorage.getItem("logMetaAddress")
  // window.onload = () => {
  //   sessionStorage.clear()
  //   localStorage.clear()
  // }

  // useEffect(() => {
  //   setInterval(() => {
  //     if(address != null && metaMaskAddress !== address){
  //       notify(
  //         'Invalid address, Please login again',
  //         'error'
  //       )
  //       window.location.href = "/"
  //       localStorage.clear()
  //       sessionStorage.clear()
  //     }
  //   }, 30000)
  // }, [])


  useEffect(() => {
    const metaMaskAddress = window?.tronWeb?.defaultAddress?.base58;
    if(!checkReference){
      return
    }
    if(checkReference && !getToken){
      localStorage.setItem("referLink",window_location)
      localStorage.setItem("logChainConnect", chainConnect)
      localStorage.setItem("metaAddress", metaMaskAddress)
      return window.location.replace('/register')
    }
  },[])
  

  return (
    <>
    <Router>

      <StateContextProvider>
      <TransactionsContextProvider>
      <CounterContextProvider>
      <GameContextProvider>
      <ResultContextProvider>
      <GiftEventProvider>
      <NotificationsProvider>
      
      <Header/>
      <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/session-gold" element={<ProtectedRoute><Gold/></ProtectedRoute>}/>
      <Route path="/session-platinum" element={<ProtectedRoute><Platinum/></ProtectedRoute>} />
      <Route path="/session-diamond" element={<ProtectedRoute><Diamond/></ProtectedRoute>} />
      <Route path="/session-palladium" element={<ProtectedRoute><Palladium/></ProtectedRoute>} />
      <Route path="/register" element={<Register refCode={getReflink} />} />
      <Route path="/withdraw" element={<ProtectedRoute><Withdraw/></ProtectedRoute>} />
      <Route path="/deposite" element={<ProtectedRoute><Deposite /></ProtectedRoute>} />
      <Route path="/transactions" element={<ProtectedRoute><UserTransactions/></ProtectedRoute> } />
      <Route path="/user-transactions" element={<ProtectedRoute><Transactions/></ProtectedRoute> } />
      <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/user-menu" element={<ProtectedRoute><UserMenu /></ProtectedRoute>} />
      <Route path="/promotions" element={<ProtectedRoute><Promotions/></ProtectedRoute>} />
      <Route path="/gift-event" element={<ProtectedRoute><GiftEvent/></ProtectedRoute>} />
      <Route path="/Userpass" element={<ProtectedRoute><Userpass/></ProtectedRoute>}/>
      <Route path="/ResetPin" element={<ProtectedRoute><ResetPin/></ProtectedRoute>}/>
      </Routes>
      
      </NotificationsProvider>
      </GiftEventProvider>
      </ResultContextProvider>
      </GameContextProvider>
      </CounterContextProvider>      
      </TransactionsContextProvider>
      </StateContextProvider>
    </Router>
    </>
  )
}

export default App;

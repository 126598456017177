import { useState } from 'react'
import { apiBaseUrl } from '../config'

const useGetRules = () => {

  const [notification, setNotification] = useState('')
  const getNotification = async () => {
    const token = sessionStorage.getItem('logToken')
    let url = apiBaseUrl + 'users/GetNotification'
    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      },
    })
    const getRulesData = await response.json()
    const data = getRulesData.notification
    setNotification(data) 
  }
  
  return { notification, getNotification }
}

export default useGetRules
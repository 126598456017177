import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Accordion } from 'react-bootstrap'
import { FaList, FaWallet, FaTicketAlt } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { BiLogInCircle } from 'react-icons/bi'
import Switch from 'react-switch'

import NavBar from '../components/Navbar'
import { StateContext } from '../service/StateContext'
import { CounterContext } from '../service/StateContext'

const UserMenu = () => {
  const [dataTF, setDataTF] = useState(true)
  const { dispatch } = useContext(StateContext)
  const { socket } = useContext(CounterContext)
  const navigate = useNavigate()

  const userHandler = () => {
    console.log('in userHandler')
    navigate('/transactions')
  }

  const depositeHandler = () => {
    console.log('in depositeHandler')
    navigate('/deposite')
  }

  const withdrawHandler = () => {
    console.log('in withdrawHandler')
    navigate('/withdraw')
  }

  const transHandler = () => {
    console.log('in transHandler')
    navigate('/user-transactions')
  }

  const giftEventHandler = () => {
    console.log('in giftEventHandler')
    navigate('/gift-event')
  }

  const promoHandler = () => {
    console.log('in promoHandler')
    navigate('/promotions')
  }

  const handleChange = (event) => {
    const val = event.target.value
    setDataTF(!dataTF)
  }
  
  const handlenavigate = ()=>{
    navigate('/Userpass')
  }

  const handleReset = () =>{
    navigate('/ResetPin')
  }

  const logoutHandler = () => {
    console.log('in logoutHandler')
    dispatch({ type: 'LOGOUT' })
    localStorage.clear()
    sessionStorage.clear()
    socket.disconnect()
    navigate('/', { replace: true })
  }

  return (
    <>

    <div>

   
    <main>
      <div className="container pt-5" style={{ marginBottom: 106 }}>
        <div className="row">
          <div className="col-sm-12">
            <Accordion style={{ backgroundColor: 'red' }}>
            <Card style={{ backgroundColor: '#C82B7C', color: 'white', cursor: 'pointer' }}>
                <Accordion.Toggle as={Card.Header} eventKey="" style={{ padding: 14 }} onClick={handlenavigate}>
                  <FaTicketAlt
                    style={{
                      fontSize: 19,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                  />{' '}
                 Update pin
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="">
                  <Card.Body> Gift Event</Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card style={{ backgroundColor: '#C82B7C', color: 'white', cursor: 'pointer' }}>
                <Accordion.Toggle as={Card.Header} eventKey="" style={{ padding: 14 }} onClick={handleReset}>
                  <FaTicketAlt
                    style={{
                      fontSize: 19,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                  />{' '}
                  Reset Pin
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="">
                  <Card.Body> Gift Event</Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card style={{ backgroundColor: '#C82B7C', color: 'white', cursor: 'pointer' }}>
                <Accordion.Toggle as={Card.Header} eventKey="" style={{ padding: 14 }} onClick={giftEventHandler}>
                  <FaTicketAlt
                    style={{
                      fontSize: 19,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                  />{' '}
                  Gift Event
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="">
                  <Card.Body> Gift Event</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ backgroundColor: '#CA2A7C', color: 'white', cursor: 'pointer' }}>
                <Accordion.Toggle as={Card.Header} eventKey="1" style={{ padding: 14 }}>
                  <FaList
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                  />{' '}
                  Orders
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div onClick={userHandler}>User Transactions</div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ backgroundColor: 'rgb(170 58 148 / 84%)', color: 'white', cursor: 'pointer' }}>
                <Accordion.Toggle as={Card.Header} eventKey="" style={{ padding: 14 }} onClick={promoHandler}>
                  <HiOutlineSpeakerphone
                    style={{
                      fontSize: 22,
                      marginRight: 5,
                      marginBottom: 3,
                      marginLeft: -5,
                    }}
                  />{' '}
                  Refferal Link
                </Accordion.Toggle>
              </Card>

              <Card style={{ backgroundColor: '#AA3586', color: 'white', cursor: 'pointer' }}>
                <Accordion.Toggle as={Card.Header} eventKey="3" style={{ padding: 14 }}>
                  <FaWallet
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                  />{' '}
                  Wallet
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div onClick={depositeHandler}>Deposit</div> <hr></hr>
                    <div onClick={withdrawHandler}>Withdraw</div> <hr></hr>
                    <div onClick={transHandler}>Transactions</div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card style={{ backgroundColor: '#8C4090', color: 'white', cursor: 'pointer' }}>
                {/* <Accordion.Toggle as={Card.Header} eventKey="4" style={{ padding: 14 }}>
                  <IoMdSettings
                    style={{
                      fontSize: 19,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                  />{' '}
                  Account Security
                </Accordion.Toggle> */}
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div style={{ display: 'flex', justifyContent: 'row' }}>
                      <div style={{ marginRight: '10px' }}> Set session timeout to 15 mins </div>

                      <div>
                        <Switch
                          onChange={handleChange}
                          checked={dataTF}
                          className="react-switch"
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={25}
                          width={48}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                <Card
                  style={{ backgroundColor: '#6051A1', color: 'white', cursor: 'pointer' }}
                  onClick={logoutHandler}
                >
                  <Accordion.Toggle as={Card.Header} style={{ padding: 14 }}>
                    <BiLogInCircle
                      style={{
                        fontSize: 19,
                        marginRight: 5,
                        marginBottom: 3,
                      }}
                    />{' '}
                    Logout
                  </Accordion.Toggle>
                </Card>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
     
    </main>
   
    </div>
     <NavBar></NavBar>
     </>
  )
}

export default UserMenu

import { useContext } from 'react'
import { useNotifications } from 'reapop'
import { apiBaseUrl } from '../config'
import socket from '../service/socket'
import { TransactionsContext } from '../service/StateContext'

const useGetTransactions = () => {
  const { notify } = useNotifications()
  const { dispatch } = useContext(TransactionsContext)

  const getTransactions = async () => {
    dispatch({ type: 'TRANSACTIONS'})
    // try {
    //   const token = sessionStorage.getItem('logToken')
    //   let url = apiBaseUrl + 'users/userResults'
    //   const response = await fetch(url, {
    //     method: 'get',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Accept: 'application/json',
    //       Authorization: token,
    //     },
    //   })
    //   const userResult = await response.json()
    //   dispatch({ type: 'TRANSACTIONS_SUCCESS', payload: userResult })
    // } catch (err) {
    //   notify('not able to fetch transactions', 'error')
    //   dispatch({ type: 'TRANSACTIONS_FAIL' })
    // } 

    socket.emit('userResults', {
      address: `${localStorage.getItem('logMetaAddress')}`,
    })
    socket.on('userResults', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: "TRANSACTIONS_SUCCESS", payload: _data })
      }else{
        dispatch({ type: "TRANSACTIONS_FAIL" })
      }
    })
  }

  const getOrders = async () => {
    dispatch({ type: 'ORDERS' })
    try {
      const token = sessionStorage.getItem('logToken')
      let url = apiBaseUrl + 'users/userTransactions'
      const response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token,
        },
      })
      const userResult = await response.json()
      const data = userResult
      dispatch({ type: 'ORDERS_SUCCESS', payload: data.Transactions })
    } catch(err) {
      notify('not able to fetch transactions', 'error')
      dispatch({ type: 'ORDERS_FAIL' }) 
    }
  }
    
  return { getTransactions, getOrders }
}
export default useGetTransactions
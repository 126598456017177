import { useContext }from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'reapop'
import socket from '../service/socket'
import { CounterContext, GameContext } from '../service/StateContext'

const useNumberGame = (gameTable, total,root,quantity) => {
  const navigate = useNavigate()
  
  const { dispatch } = useContext(GameContext)
  const { notify } = useNotifications()
  const { state } = useContext(CounterContext)

  const joinGame = async () => {
    dispatch({ type: 'JOIN_NUMBER' })
    if (window.tronWeb != undefined) {
      try {
        const metaMaskAddressData = window.tronWeb.defaultAddress.base58
        localStorage.setItem('logMetaAddress', metaMaskAddressData)
        console.log(metaMaskAddressData)
        if (metaMaskAddressData == false) {
          dispatch({ type: 'JOIN_NUMBER_FAIL' })
          notify('Wallet not connected, please join with TronLink wallet', 'error')
        } else {
          let data = {
            address: `${localStorage.getItem('logMetaAddress')}`,
            amount: JSON.stringify(total),
            gameName: `${gameTable.toLowerCase()}`,
            number: localStorage.getItem('nbr'),
            period: state?.period
          }
          const timeData = localStorage.getItem('countdown')
          let disableGame = timeData?.toString().split(':')
          if (disableGame[0]  == '0' && Number(disableGame[1]) <= 30) {
            dispatch({ type: 'JOIN_NUMBER_FAIL'})
            notify('Game locked , Please join game later', 'error')
          } else {
            socket.emit('joinGame', data)
            dispatch({ type: 'JOIN_NUMBER_SUCCESS',payload: data })
            notify('Successfully joined game', 'success')
            socket.on('joinGame', (_data) => {
              if(_data.status === 'success') {
                // dispatch({ type: 'JOIN_COLOR_SUCCESS',payload: _data })
                // notify('Successfully joined game', 'success')
                localStorage.setItem("model",true);
                navigate("."+root)
                navigate(root)
              }else if(_data.message === 'token_not_found'){
                navigate('/',{replace: true})
                notify('Please Login again', 'error')
              }else{
                dispatch({ type: 'JOIN_NUMBER_FAIL' })
                navigate("."+root)
                navigate(root)
              }
            })
          }
        } 
      } catch (error) {
        console.error({error})
        dispatch({ type: 'JOIN_NUMBER_FAIL' })
        navigate("."+root)
        navigate(root)
        //notify('Unfortunately the wallet is not an active member of the platform.you can join the program below', 'error')
        // navigate('/', { replace: true })
        
      }
    } else {
      dispatch({ type: 'JOIN_NUMBER_FAIL' })
      notify('Non-Tronlink browser detected. You should consider trying Tronlink Wallet!', 'error')
    }
  }

  return { joinGame }
}

export default useNumberGame
import { useState, useEffect, useContext } from 'react'
import useGetDetails from '../hooks/useGetDetails'
import { StateContext } from '../service/StateContext'

const Promotions = () => {
    const [codeLink, setCodeLink] = useState()
    const link = 'http://gagner.stsblockchain.xyz/#/register?'
    const [copied, setCopied] = useState(false)

    const { getDetails } = useGetDetails()
    const { state } = useContext(StateContext)

    const copy = (value) => {
      const el = document.createElement('input')
      el.value = value
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      setCopied(true)
    }

  useEffect(() => {
    getDetails()
    const window_location = window.location.origin;
    const refferal = window_location + "/#/invite?referal=" + state.code
    setCodeLink(refferal)
  },[])
  return (
    <main>
      <div className="row app">
        <div className="col sm-12 px-0">
          <h4 className="promo-text">Promotions</h4>
          <div className="">
            <input className="formswith" value={codeLink} style={{ marginTop: '30px' }} />
            <div className="btn-main">
              <button
                className="btn btn-block withBtn"
                onClick={() => {
                  copy(codeLink)
                }}
              >
                {!copied ? 'Copy link' : 'Copied!'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Promotions

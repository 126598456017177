import { useContext } from "react"
import { useNotifications } from "reapop"
import { apiBaseUrl } from "../config"
import { GiftEventContext } from "../service/StateContext"

const useGiftEvent = () => {

  const { notify } = useNotifications()
  const { dispatch } = useContext(GiftEventContext)

  const getGiftEvent = async () => {
    dispatch({ type: 'GIFTEVENT'})
    const token = sessionStorage.getItem('logToken')
    let url = apiBaseUrl + 'users/liveGiftEvents'
    const response = await fetch(url, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": token,
      },
    });
    const result = await response.json();
    if (!!result.Result){
      dispatch({ type: 'GIFTEVENT_SUCCESS', payload: result.Result })
    } else {
      notify('Failed to fetch gift events', 'error')
      dispatch({ type: 'GIFTEVENT_FAIL' })
    }
  }
  return { getGiftEvent }
}

export default useGiftEvent
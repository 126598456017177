import { useState, useContext } from 'react'
import DepositeModal from '../components/DepositeModal'
import NavBar from '../components/Navbar'
import { StateContext } from '../service/StateContext'
import tron from './../img/tron.png'

const Deposite = () => {
  const [amount, setAmount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const { state: userState }  = useContext(StateContext)

  const sendHundred = (amt) => {
    console.log('in sendHunred');
    setAmount(amt)
    setShowModal(true)
  }

  return (
    <div>
      <main>
        <div className="row app" style={{ marginBottom: '100px' }}>
          <div className="col sm-12 px-0">
            <h5 style={{ textAlign: 'center', marginTop: 20 }}>
              My balance : <img src={tron} style={{ width: 18 }} /> <strong>{userState.balance}</strong>
            </h5>
            <h4 style={{ textAlign: 'center', marginTop: 20 }}>Add Tron</h4>
            <div className="main">
              <div className="main_next">
                <input
                  className="money pay-option-check"
                  value="1"
                  onClick={() => {
                    sendHundred(1)
                  }}
                  readOnly
                />
                <input
                  className="money pay-option-check"
                  value="10"
                  onClick={() => {
                    sendHundred(10)
                  }}
                  readOnly
                />
                <input
                  className="money pay-option-check"
                  value="100"
                  onClick={() => {
                    sendHundred(100)
                  }}
                  readOnly
                />
              </div>
              <div className="main_next">
                <input
                  className="money pay-option-check"
                  value="1K"
                  onClick={() => {
                    sendHundred(1000)
                  }}
                  readOnly
                />
                <input
                  className="money pay-option-check"
                  value="10K"
                  onClick={() => {
                    sendHundred(10000)
                  }}
                  readOnly
                />
                <input
                  className="money pay-option-check"
                  value="100K"
                  onClick={() => {
                    sendHundred(100000)
                  }}
                  readOnly
                />
              </div>
              <div className="main_next">
                <input
                  className="money pay-option-check"
                  value="1M"
                  onClick={() => {
                    sendHundred(1000000)
                  }}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <DepositeModal 
        showModal={showModal}
        setShowModal={()=>setShowModal()}
        amount={amount}
        />
        <NavBar />
      </main>
    </div>
  )
}

export default Deposite
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { StateContext } from '../service/StateContext'
import useSocket from './useSocket'

const useGetDetails = () => {
  const { socket } = useSocket()
  const navigate = useNavigate()
  const {  dispatch } = useContext(StateContext)
  const getDetails = async () => {
    dispatch({ type: 'DETAILS'})
    socket.on('connect', (arg) => {
      socket.connect()
    })

    socket.emit('userDetails', {
      address: `${localStorage.getItem('logMetaAddress')}`,
    })
    socket.on('userDetails', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: "DETAILS_SUCCESS", payload: _data })
      }else{
        dispatch({ type: "DETAILS_FAIL" })
        navigate("/", {replace: true})
      }
    })
  }

  return { getDetails }
}

export default useGetDetails
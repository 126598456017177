import { useState, useEffect, useContext} from 'react'
import NavBar from '../components/Navbar'
import useGetTransactions from '../hooks/useGetTransactions'
import socket from '../service/socket'
import { TransactionsContext } from '../service/StateContext'
import useGetDetails from '../hooks/useGetDetails'


const Transactions = () => {
  const timeStamp = new Date()
  const { getDetails } = useGetDetails()

  const { getOrders } = useGetTransactions()
  const { state } = useContext(TransactionsContext)
  const [transactionsState, setTrasactionsState] = useState("");
  useEffect(() => {
    getOrders()
  }, [])

  useEffect(() => {
    socket.on("userTransactions", (arg) => {
      // console.log(arg, "text11");
      setTrasactionsState(arg.Transactions)
      // world
    });
  }, [])

  useEffect(() => {
    setInterval(() => {
      // console.log("Text")
      socket.emit("userTransactions", { "address": localStorage.getItem("logMetaAddress") });
      getDetails()
    }, 5000)
  }, [])



  return (
    <main>
    <div className="row app">
      <div className="col sm-12 px-0">
        <div className="table-responsive gold-record-table">
          <table className="table">
            <thead className="tran-head">
              <th>Address</th>
              <th>Amount</th>
              <th>Service Charge</th>
              <th>Status</th>
              <th>Type</th>
            </thead>
            <tbody>
              {console.log(
                new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }).format(timeStamp)
              )}

              {transactionsState === undefined || transactionsState === null || transactionsState.length <= 0
                ? 'Loading...'
                : transactionsState.map((item) => {
                    return (
                      <tr>
                        <td>{item.address}</td>
                        <td>{item.amount}</td>
                        <td>{item.serviceCharge}</td>
                        <td>{item.status}</td>
                        <td>
                          {item.type === 'recharge' ? (
                            <div>Add Tron</div>
                          ) : (
                            <div className="gamename-text"> {item.type}</div>
                          )}
                        </td>
                      </tr>
                    )
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <NavBar />
  </main>

  )
}

export default Transactions
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'reapop'
import { apiBaseUrl, networkUrl } from '../config'

import { StateContext, CounterContext } from '../service/StateContext'

const useLogin = () => {

  const { state,dispatch } = useContext(StateContext)
  const { socket } = useContext(CounterContext)

  const navigate = useNavigate()
  const { notify } = useNotifications()

  const getAdminData = (token) =>{
    let url = apiBaseUrl + 'users/getAdminAddress'
    fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      },
    })
    .then(result => result.json())
    .then((result) => {
  
      console.log(result,'resultresultresultresult')
      const setAddress = result.adminAddress
      localStorage.setItem('adAddress', setAddress)
    })
    .catch(err => console.error({err}))

  }

  const login  = async () => {
    let checkChainConnect = window?.tronWeb?.fullNode?.host?.includes('https://api.')
    const chainConnect = window.tronWeb.fullNode.host
    dispatch({ type: 'LOGIN' })
    console.log('button click-authorization');
    if (window.tronWeb != undefined) {
      try {
        const metaMaskAddressData = await window.tronWeb.defaultAddress.base58;
        // const chainConnect = await window.tronWeb.fullNode.host
        localStorage.setItem("logMetaAddress", metaMaskAddressData)
        localStorage.setItem("logChainConnect", chainConnect)
        if (metaMaskAddressData == false) {
          dispatch({ type: 'LOGIN_ERROR' })
          // notify(' not Detected, please install or unlock', 'error')
        } else {
          console.log(metaMaskAddressData);
          const hexMessage = await window.tronWeb.toHex(metaMaskAddressData)
          console.log(hexMessage);
        }
      } catch (error) {
        dispatch({ type: 'LOGIN_ERROR' })
        notify('Please connect with tron wallet', 'error')
        return false
      }
    } else {
      dispatch({ type: 'LOGIN_ERROR' })
      notify('Tron Wallet not installed or Tron wallet locked', 'error')
    }

    if(chainConnect === networkUrl){
      let data = {
        address: localStorage.getItem("logMetaAddress"),
        isLoggedIn: "true",
        networkid: `${localStorage.getItem("logChainConnect") === networkUrl ? '1' : '2'}`
      };

      console.log(data);
      let url = apiBaseUrl + 'users/login'
      let result = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        },
        body: JSON.stringify(data)
      });
      result = await result.json()
      console.log(result);

      if (result.message === "User not Registered" && checkChainConnect) {
        notify("User not Registered", 'error');
        return false
      }

      if(!checkChainConnect){
        notify("Wallet not connected", 'error');
        return false
      }

      if (result.status === "success") {
        sessionStorage.setItem("logToken", result.token)
        sessionStorage.setItem("isLoggedIn", true)
        getAdminData(result.token)
        socket.connect()
        dispatch({ type: 'LOGIN_SUCCESS' })
        notify('Welcome back', 'success')
        if(result.hasTransactionPin){
          navigate('/session-gold', { replace: true })
        }else{
          navigate('/Userpass', { replace: true })
        }
        return false
      }else if(result.status === "fail"){
        notify(result.message, 'error')
      }
    }else{
      notify("Please change to mainnet." , 'error')
    }
  };
  
  return { login }
}

export default useLogin
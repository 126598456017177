import { useContext, useState } from 'react'
import NavBar from '../components/Navbar'
import useWithdraw from '../hooks/useWithdraw'
import { StateContext } from '../service/StateContext'

const Withdraw = () => {
  const [balance, setBalance] = useState(0)
  const [withdrawAmount, setWithdrawAmount] = useState(0)
  const [withdrawAddress, setwithdrawAddress] = useState('')
  const [showPassword, setShowPassword] = useState(null);
  const [pin,setpin] = useState(null);
  const { withdraw } = useWithdraw(withdrawAddress, withdrawAmount,pin)
  const { state } = useContext(StateContext)
  
  const withdrawHandler = async () => {
    console.log('in withdraw handler')
    withdraw()
        
  }

  const handleChangeAddress = (event) => {
    const address = event.target.value
    setwithdrawAddress(address)
  }

  const handleChangeAmount = (event) => {
    const amount = event.target.value
    setWithdrawAmount(amount)
  }
  return (
    <div>
      <main>
        <div className="row app">
          <div className="col sm-12 px-0">
            <div className="withdrawBtn">
              <input 
              placeholder="Enter Amount" 
              value={withdrawAmount} 
              className="formswith" 
              onChange={handleChangeAmount} 
              type="number" 
              />
              {withdrawAmount > state.balance ? <div style={{ color: "red" }}>Please check the balance</div> : null}
              <input 
                placeholder="Enter Address" 
                value={withdrawAddress} 
                className="formswith" 
                onChange={handleChangeAddress} 
                style={{ marginTop: "30px" }} 
              />
              
              <input 
                placeholder="Enter Pin" 
                type={showPassword ? 'text' : 'password'}
                value={pin} 
                className="formswith" 
                onChange={(e) => setpin(e.target.value)} 
                style={{ marginTop: "30px" }} 
              />
              <span onClick={() => setShowPassword(!showPassword)} className="eyeic"> { !showPassword ? <i className="fas fa-eye-slash"></i> : <i class="fa fa-eye"></i>} </span>   
              <div className="btn-main">
                {
                  withdrawAmount < 1 || withdrawAddress.length < 1 || withdrawAmount > state.balance ?
                  <button className="btn btn-block withBtn" disabled onClick={withdrawHandler}>Withdraw</button> :
                  <button className="btn btn-block withBtn" onClick={withdrawHandler}>Withdraw</button>
                }
              </div>
            </div>
          </div>
        </div>
        <NavBar />
      </main>
    </div>
  )
}

export default Withdraw
import { useState, useEffect, useContext} from 'react'
import { CounterContext } from '../service/StateContext'

const GameTable = ({
  setShowModal,
  setShowModalNumber
}) => {

  const [disabled, setDisabled] = useState(false)

  const [color, setColor] = useState('')
  const [numbers, setNumbers] = useState(0)
  
  const { state } = useContext(CounterContext)

  useEffect(() => {
    const timeData = state.counter
    let disableGame = timeData?.toString().split(':')
    if (disableGame[0] == '0' && Number(disableGame[1]) <= 30) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  },[state.counter])

  const open = (id) => {
    setColor(id)
    setShowModal(true)    
    localStorage.setItem('clr', id)
  }

  const selectNumber = (id) => {
    console.log(id)
    setNumbers(id)
    setShowModalNumber(true)
    localStorage.setItem('nbr', id.toString())
  }

  return (
    <div>
      {disabled === true ? (
        <div className="join-game pt-3" disabled={disabled}>
          <div>
            <div className="join-game--btns">
              <a
                className="btn btn-green rounded-pill text-white"
                value="green"
                onClick={() => {
                  open('green')
                }}
              >
                Join Green
              </a>
              <a
                className="btn btn-violet rounded-pill text-white"
                value="purple"
                onClick={() => {
                  open('purple')
                }}
              >
                Join Purple
              </a>
              <a
                className="btn btn-red rounded-pill text-white"
                value="red"
                onClick={() => {
                  open('red')
                }}
              >
                Join Red
              </a>
            </div>
            <div className="game-count game-count-1">
              <p
                className="select-no"
                value={0}
                onClick={() => {
                  selectNumber(0)
                }}
              >
                0
              </p>
              <p
                className="select-no"
                value={1}
                onClick={() => {
                  selectNumber(1)
                }}
              >
                1
              </p>
              <p
                className="select-no"
                value={2}
                onClick={() => {
                  selectNumber(2)
                }}
              >
                2
              </p>
              <p
                className="select-no"
                value={3}
                onClick={() => {
                  selectNumber(3)
                }}
              >
                3
              </p>
              <p
                className="select-no"
                value={4}
                onClick={() => {
                  selectNumber(4)
                }}
              >
                4
              </p>
            </div>
            <div className="game-count game-count-2">
              <p
                className="select-no"
                value={5}
                onClick={() => {
                  selectNumber(5)
                }}
              >
                5
              </p>
              <p
                className="select-no"
                value={6}
                onClick={() => {
                  selectNumber(6)
                }}
              >
                6
              </p>
              <p
                className="select-no"
                value={7}
                onClick={() => {
                  selectNumber(7)
                }}
              >
                7
              </p>
              <p
                className="select-no"
                value={8}
                onClick={() => {
                  selectNumber(8)
                }}
              >
                8
              </p>
              <p
                className="select-no"
                value={9}
                onClick={() => {
                  selectNumber(9)
                }}
              >
                9
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="join-game pt-3">
          <div>
            <div className="join-game--btns">
              <a
                className="btn btn-green rounded-pill text-white"
                value="green"
                onClick={() => {
                  open('green')
                }}
              >
                Join Green
              </a>
              <a
                className="btn btn-violet rounded-pill text-white"
                value="purple"
                onClick={() => {
                  open('purple')
                }}
              >
                Join Purple
              </a>
              <a
                className="btn btn-red rounded-pill text-white"
                value="red"
                onClick={() => {
                  open('red')
                }}
              >
                Join Red
              </a>
            </div>
            <div className="game-count game-count-1">
              <p
                className="select-no"
                value={0}
                onClick={() => {
                  selectNumber(0)
                }}
              >
                0
              </p>
              <p
                className="select-no"
                value={1}
                onClick={() => {
                  selectNumber(1)
                }}
              >
                1
              </p>
              <p
                className="select-no"
                value={2}
                onClick={() => {
                  selectNumber(2)
                }}
              >
                2
              </p>
              <p
                className="select-no"
                value={3}
                onClick={() => {
                  selectNumber(3)
                }}
              >
                3
              </p>
              <p
                className="select-no"
                value={4}
                onClick={() => {
                  selectNumber(4)
                }}
              >
                4
              </p>
            </div>
            <div className="game-count game-count-2">
              <p
                className="select-no"
                value={5}
                onClick={() => {
                  selectNumber(5)
                }}
              >
                5
              </p>
              <p
                className="select-no"
                value={6}
                onClick={() => {
                  selectNumber(6)
                }}
              >
                6
              </p>
              <p
                className="select-no"
                value={7}
                onClick={() => {
                  selectNumber(7)
                }}
              >
                7
              </p>
              <p
                className="select-no"
                value={8}
                onClick={() => {
                  selectNumber(8)
                }}
              >
                8
              </p>
              <p
                className="select-no"
                value={9}
                onClick={() => {
                  selectNumber(9)
                }}
              >
                9
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GameTable
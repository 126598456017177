import { useContext } from 'react'
import { TransactionsContext } from '../service/StateContext'
import useGetDetails from './useGetDetails'
import { useNotifications } from 'reapop'
import { apiBaseUrl, networkUrl } from '../config'

const useWithdraw = (withaddress, withamount,pin) => {
  const { dispatch } = useContext(TransactionsContext)
  const { getDetails } = useGetDetails()
  const { notify } = useNotifications()

  const withdraw = async () => {
    dispatch({ type: 'WITHDRAW' })
    if (window.tronWeb != undefined) {
      try {
        const metaMaskAddressData = await window.tronWeb.defaultAddress.base58
        const chainConnect = await window.tronWeb.fullNode.host
        localStorage.setItem('logMetaAddress', metaMaskAddressData)
        localStorage.setItem("logChainConnect", chainConnect)
        if (metaMaskAddressData == false) {
          dispatch({ type: 'WITHDRAW_FAIL' })
          notify('Tron wallet not connected', 'error')
        } else {
          console.log(metaMaskAddressData)
          const hexMessage = await window.tronWeb.toHex(metaMaskAddressData)
          console.log(hexMessage)
          const signature = await window.tronWeb.trx.sign(hexMessage)
          localStorage.setItem('logSignature', signature)
          console.log(signature)
          let data = {
            amount: withamount,
            toAddress: withaddress,
            signature: localStorage.getItem('logSignature'),
            pin:pin,
            networkid: `${localStorage.getItem("logChainConnect") === networkUrl ? '1' : '2'}`
          }
          console.log(data)
          const token = sessionStorage.getItem('logToken')
          let url = apiBaseUrl + 'users/withdraw'
          let response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: token,
            },
            body: JSON.stringify(data),
          })
          const result = await response.json()
          const dataBalance = result

          if (dataBalance.status === 'success') {
            console.log('done')
            dispatch({ type: 'WITHDRAW_SUCCESS' })
            notify('Transaction Initiated sucessfully.', 'success')
            getDetails()
          } else if (dataBalance.message === 'Invalid recipient address provided') {
            dispatch({ type: 'WITHDRAW_FAIL' })
            notify('Invalid recipient address provided.', 'error')
          } else if (dataBalance.message === 'Low balance') {
            dispatch({ type: 'WITHDRAW_FAIL' })
            notify('Low balance.', 'error')
          } else if (
            dataBalance.message ===
            'class org.tron.core.exception.ContractValidateExce…ransferContract error, balance is not sufficient.'
          ) {
            dispatch({ type: 'WITHDRAW_FAIL' })
            notify('Low balance.', 'error')
          } else {
            dispatch({ type: 'WITHDRAW_FAIL' })
            notify(dataBalance.message, 'error')
          }
        }
      } catch (error) {
        console.log('catch unauthorization block:')
        dispatch({ type: 'WITHDRAW_FAIL' })
        notify(
          'Currently withdraw transaction has been cancelled',
          'error'
        )
      }
    } else {
      dispatch({ type: 'WITHDRAW_FAIL' })
      notify('Non-Tronlink browser detected. You should consider trying Tronlink Wallet!', 'error')
    }
  }

  return { withdraw }
}

export default useWithdraw

import { useState } from 'react' 
import { Modal } from 'react-bootstrap'
import useDeposite from '../hooks/useDeposite'

const DepositeModal = ({
  showModal,
  setShowModal,
  amount
}) => {

  const [quantity, setQuantity] = useState(1)
  const { deposite } = useDeposite(quantity*amount)
  const depositeHandler = () => {
    deposite()
    setShowModal(false)
  }

  const increaseItem = () => {
    setQuantity(quantity + 1)
  }

  const decreaseItem = () => {
    if (quantity <= 1) {
      setQuantity(1)
    }
    else {
      setQuantity(quantity - 1)
    }
  }

  const handleChange = (event) => {
    const val = event.target.value
    setQuantity(val)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add tron</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="align">
          <div className="tronText">Selected tron</div>
          <div className="main_next" style={{ marginLeft: '19px' }}>
            <input className="moneyGameDep pay-option-check" value={amount} readOnly />
          </div>
        </div>
        <div className="align">
          <div className="tronText">Number</div>
          <div className="main_nextID" style={{ marginLeft: '80px' }}>
            <button onClick={decreaseItem} className={quantity == 1 ? "btnID disabledMinus" : "btnID"}>
              -
            </button>
            <input
              className="inputne"
              value={Number(quantity).toString()}
              onChange={handleChange}
              style={{ width: '78px' }}
            />
            <button onClick={increaseItem} className="btnI">
              +
            </button>
          </div>
        </div>
        <div className="align">
          <div className="tronText">Tron</div>
          <div className="amountValue" style={{ marginLeft: '108px' }}>
            {quantity * amount}
            {localStorage.setItem('amount', quantity * amount)}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-btn">
        <button className="btn-block" onClick={closeModal}>
          Cancel
        </button>
        <button
          style={{ marginTop: '2px' }}
          className="btn-block"
          onClick={depositeHandler}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DepositeModal
import { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Modal } from 'react-bootstrap'
import useGetRules from '../hooks/useGetRules'
import socket from '../service/socket'

const RulesModal = ({
  showModal,
  setShowModal
}) => {

  const { rules, getRules } = useGetRules()

  const [rulesEmit, setRulesEmit] = useState("");

  useEffect(() => {
    socket.on("getRule", (arg) => {
      setRulesEmit(arg.adminAddress) // world
    });
   }, [])

  return (
    <Modal show={showModal} onHide={() => {setShowModal(false)}}>
      <Modal.Body style={{ padding: '22px' }}>
        <div>{ReactHtmlParser(rulesEmit)}</div>
      </Modal.Body>
      <Modal.Footer className="footer-btn">
        <button className="btn-block" onClick={() => setShowModal(false)}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default RulesModal

import { useEffect, useState } from 'react'
import NavBar from '../components/Navbar'
import ReactHtmlParser from 'react-html-parser'
import useGetRules from '../hooks/useGetRules'
import socket from '../service/socket'

const Home = () => {
  const { rules, getRules } = useGetRules()
  const [rulesEmit, setRulesEmit] = useState("");

  useEffect(() => {
    socket.on("getRule", (arg) => {
      setRulesEmit(arg.adminAddress) // world
    });
   }, [])


  return (
    <>
    <div>
      <main>
        <div className="row app">
          <div className="col sm-12 px-0">
            <div className="notify">
              <div >
                {ReactHtmlParser(rulesEmit)}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <NavBar></NavBar>
    </>
  );
}

export default Home
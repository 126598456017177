import { useState, useEffect, useContext } from 'react'

import NavBar from '../components/Navbar'
import Navpills from '../components/Navpills'
import Counter from '../components/Counter'
import GameTable from '../components/GameTable'
import UserResults from '../components/UserResults'
import AllResults from '../components/AllResults'
import ColorModal from '../components/ColorModal'
import NumberModal from '../components/NumberModal'
import { CounterContext } from '../service/StateContext'

const Gold = () => {
  const gameTable = 'GOLD'
  const [showModal, setShowModal] = useState(false)
  const [showModalNumber, setShowModalNumber] = useState(false)
  const root = "/session-gold"
  const { state } = useContext(CounterContext)
  let checkCountDown = state.counter == "0:01"
  useEffect(()=>{
    const model = localStorage?.getItem("model")
    if(model===true){
      setShowModal(false);
      setShowModalNumber(false);
    }
    //console.log(model)
  },[NumberModal][ColorModal])

  useEffect(() => {
    if (checkCountDown) {
      window.location.reload(false);
    }
  }, [checkCountDown])

  return (
    <>
      <div className="row app">
        <div className="col sm-12 px-0">
          <div className="app--main">
            <Navpills />
            <div className="container-sm">
              <div className="tab-content" id="gift-eventContent">
                  <div className="row">
                    <div className="col-md-6">
                      <Counter />
                      <GameTable setShowModal={setShowModal} setShowModalNumber={setShowModalNumber} />
                      <UserResults gameTable={gameTable} />
                    </div>
                    <div className="col-md-6">
                      <AllResults gameTable={gameTable} />
                    </div>
                  </div>
                
                <ColorModal root={root} showModal={showModal} setShowModal={setShowModal} gameTable={gameTable} />
                <NumberModal
                  root = {root}
                  showModalNumber={showModalNumber}
                  setShowModalNumber={setShowModalNumber}
                  gameTable={gameTable}
                />
              </div>
            </div>
          </div>
        </div>
        <NavBar />
      </div>
    </>
  )
}

export default Gold

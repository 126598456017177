import { useContext, useCallback } from 'react'
import { apiBaseUrl } from '../config'
import { ResultContext } from '../service/StateContext'
import useSocket from './useSocket'

const useGetResults= () => {
  const { socket } = useSocket()
  const { dispatch } = useContext(ResultContext)

  const getAllResults = useCallback (async( page ) => {
    dispatch({ type: 'ALLRESULTS' })
    socket.emit('AllResult', {
      address: `${localStorage.getItem('logMetaAddress')}`,
      page: page,
      limit: 40
    })
    socket.on('AllResult', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: 'ALLRESULTS_SUCCESS', payload: _data })
      }else{
        dispatch({ type: 'ALLRESUTLS_FAIL' })
      }
    })
  }, [])

  const getUserGoldResults = useCallback(async(page) => {
    
    dispatch({ type: 'USERGOLD' })
    let pageUserData = {
      page: page,
      limit: 5,
      address: `${localStorage.getItem('logMetaAddress')}`,
    }
    socket.emit('userResultsGold', pageUserData)
    socket.on('userResultsGold', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: 'USERGOLD_SUCCESS', payload: _data }) 
      }else{
        dispatch({ type: 'USERGOLD_FAIL' })
      }
    })

  }, [])

  const getUserPlatinumResults = useCallback(async( page ) => {
    dispatch({ type: 'USERPLATINUM' })

    let pageUserData = {
      page: page,
      limit: 5,
      address: `${localStorage.getItem('logMetaAddress')}`,
    }
    socket.emit('userResultsPlatinum', pageUserData)
    socket.on('userResultsPlatinum', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: 'USERPLATINUM_SUCCESS', payload: _data })
      }else{
        dispatch({ type: 'USERPLATINUM_FAIL' })
      }
    })

  }, [])

  const getUserDiamondResults = useCallback(async( page ) => {
    dispatch({ type: 'USERDIAMOND' })

    let pageUserData = {
      page: page,
      limit: 5,
      address: `${localStorage.getItem('logMetaAddress')}`,
    }
    socket.emit('userResultsDiamond', pageUserData)
    socket.on('userResultsDiamond', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: 'USERDIAMOND_SUCCESS', payload: _data })
      }else{
        dispatch({ type: 'USERDIAMOND_FAIL' })
      }
    })


  }, [])

  const getUserPalladiumResults = useCallback(async( page ) => {
    dispatch({ type: 'USERPALLADIUM' })

    let pageUserData = {
      page: page,
      limit: 5,
      address: `${localStorage.getItem('logMetaAddress')}`,
    }
    socket.emit('userResultsPalladium', pageUserData)
    socket.on('userResultsPalladium', (_data) => {
      if(_data.status === 'success') {
        dispatch({ type: 'USERPALLADIUM_SUCCESS', payload: _data })
      }else{
        dispatch({ type: 'USERPALLADIUM_FAIL' })
      }
    })

  }, [])

  const getAllUserResults = useCallback(() => {
    getUserGoldResults(1)
    getUserDiamondResults(1)
    getUserPlatinumResults(1)
    getUserPalladiumResults(1)
  }, [
    getUserGoldResults,
    getUserDiamondResults,
    getUserPlatinumResults,
    getUserPalladiumResults])

  return { getAllResults, getUserGoldResults, getUserPlatinumResults, getUserDiamondResults, getUserPalladiumResults, getAllUserResults }
}

export default useGetResults
import { createContext, useReducer, useMemo } from "react"
import { apiBaseUrl } from "../config"
import socket from './socket'

export const StateContext = createContext({})
export const GameContext = createContext({})
export const TransactionsContext = createContext({})
export const CounterContext = createContext({})
export const ResultContext = createContext({})
export const GiftEventContext = createContext({})


export const GiftEventProvider = (props) => {
  const [state, dispatch] = useReducer(giftReducer, initGiftState)

  return (
    <GiftEventContext.Provider value={{ state, dispatch }}>
      { props.children }
    </GiftEventContext.Provider>
  )
}

export const ResultContextProvider = (props) => {
  const [state, dispatch] = useReducer(resultReducer, initResultState)

  const [memoState, memoDispatch]= useMemo(()=> {return [state, dispatch]}, [state])
  
  return (
    <ResultContext.Provider value={{ state: memoState, dispatch: memoDispatch }}>
      { props.children }
    </ResultContext.Provider>
  )
}

export const CounterContextProvider = (props) => {
  const [state, dispatch] = useReducer(counterReducer, initCounterState)

  return (
    <CounterContext.Provider value={{ state, dispatch, socket }}>
      {props.children}
    </CounterContext.Provider>
  )
}

export const TransactionsContextProvider = (props) => {
  const [state, dispatch] = useReducer(transReducer, initTranState)

  // const token = sessionStorage.getItem('logToken')
  //   let url = apiBaseUrl + 'users/getAdminAddress'
  //   fetch(url, {
  //     method: 'get',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //       Authorization: token && token,
  //     },
  //   })
  //   .then(result => result.json())
  //   .then((result) => {
  
  //     console.log(result,'resultresultresultresult')
  //     const setAddress = result.adminAddress
  //     localStorage.setItem('adAddress', setAddress)
  //   })
  //   .catch(err => console.error({err}))

  return(
    <TransactionsContext.Provider value={{ state, dispatch }}>
      { props.children }
    </TransactionsContext.Provider>
  )
}

export const GameContextProvider = (props) => {
  const [state, dispatch] = useReducer(gameReducer, initGameState)
  
  return(
    <GameContext.Provider value={{ state, dispatch }}>
      { props.children }
    </GameContext.Provider>
  )
}

export const StateContextProvider = (props) => {
  const [state, dispatch] = useReducer(stateReducer, initState)

  const [memoState, memoDispatch] = useMemo(() => {return [state, dispatch ]}, [state])
  
  return (
    <StateContext.Provider value={{ state: memoState, dispatch: memoDispatch }}>
      { props.children }
    </StateContext.Provider>
  )
}

export const giftReducer = (state, action) => {
  switch(action.type) {
    case 'GIFTEVENT': {
      return {
        ...state,
        isGiftEvent: true
      }
    }
    case 'GIFTEVENT_SUCCESS': {
      return {
        ...state,
        isGiftEvent: false,
        successGiftEvent: true,
        giftEvent: action.payload
      }
    }
    case 'GIFTEVENT_FAIL': {
      return {
        ...state,
        isGiftEvent: false,
        successGiftEvent: false,
        errorGiftEvent: 'failed to fetch GiftEvent'
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export const resultReducer = (state, action) => {
  switch (action.type) {
    case 'ALLRESULTS': {
      return {
        ...state,
        isAllResults: true,
      }
    }
    case 'ALLRESULTS_SUCCESS': {
      return {
        ...state,
        isAllResults: false,
        successAllResults: true,
        data: action.payload.Result,
        pageData: action.payload
      }
    }
    case 'ALLRESULTS_FAIL': {
      return {
        ...state,
        isAllResults: false,
        successAllResults: false,
        errorAllResults: 'failed to fetch All Results'
      }
    }
    case 'USERGOLD': {
      return {
        ...state,
        isUserResultsGold: true,
      }
    }
    case 'USERGOLD_SUCCESS': {
      return {
        ...state,
        isUserResultsGold: false,
        successUserResultsGold: true,
        userDataGold: action.payload.Result,
        pageUserDataGold: action.payload
      }
    }
    case 'USERGOLD_FAIL': {
      return {
        ...state,
        isUserResultsGold: false,
        successUserResultsGold: false,
        errorUserResultsGold: 'failed to fetch Gold Results'
      }
    }
    case 'USERPLATINUM': {
      return {
        ...state,
        isUserResultsPlatinum: true,
      }
    }
    case 'USERPLATINUM_SUCCESS': {
      return {
        ...state,
        isUserResultsPlatinum: false,
        successUserResultsPlatinum: true,
        userDataPlatinum: action.payload.Result,
        pageUserDataPlatinum: action.payload
      }
    }
    case 'USERPLATINUM_FAIL': {
      return {
        ...state,
        isUserResultsPlatinum: false,
        successUserResultsPlatinum: false,
        errorUserResultsPlatinum: 'failed to fetch Platinum Results'
      }
    }
    case 'USERDIAMOND': {
      return {
        ...state,
        isUserResultsDiamond: true,
      }
    }
    case 'USERDIAMOND_SUCCESS': {
      return {
        ...state,
        isUserResultsDiamond: false,
        successUserResultsDiamond: true,
        userDataDiamond: action.payload.Result,
        pageUserDataDiamond: action.payload
      }
    }
    case 'USERDIAMOND_FAIL': {
      return {
        ...state,
        isUserResultsDiamond: false,
        successUserResultsDiamond: false,
        errorUserResultsDiamond: 'failed to fetch Diamond Results'
      }
    }
    case 'USERPALLADIUM': {
      return {
        ...state,
        isUserResultsPalladium: true,
      }
    }
    case 'USERPALLADIUM_SUCCESS': {
      return {
        ...state,
        isUserResultsPalladium: false,
        successUserResultsPalladium: true,
        userDataPalladium: action.payload.Result,
        pageUserDataPalladium: action.payload
      }
    }
    case 'USERPALLADIUM_FAIL': {
      return {
        ...state,
        isUserResultsPalladium: false,
        successUserResultsPalladium: false,
        errorUserResultsPalladium: 'failed to fetch Palladium Results'
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export const transReducer = (state, action) => {
  switch (action.type) {
    case 'DEPOSITE': {
      return {
        ...state,
        isDepositeLoading: true,
      }
    }
    case 'DEPOSITE_SUCCESS': {
      return {
        ...state,
        isDepositeLoading: false,
        successDeposite: true,
      }
    }
    case 'DEPOSITE_FAIL': {
      return {
        ...state,
        isDepositeLoading: false,
        successDeposite: false,
        errorDeposite: 'failed to deposite'
      }
    }
    case 'WITHDRAW': {
      return {
        ...state,
        isWithdrawLoading: true,
      }
    }
    case 'WITHDRAW_SUCCESS': {
      return {
        ...state,
        isWithdrawLoading: false,
        successWithdraw: true,
      }
    }
    case 'WITHDRAW_FAIL': {
      return {
        ...state,
        isWithdrawLoading: false,
        successWithdraw: false,
        errorWithdraw: 'failed to deposite'
      }
    }
    case 'TRANSACTIONS': {
      return {
        ...state,
        isTransactionLoading: true,
      }
    }
    case 'TRANSACTIONS_SUCCESS': {
      return {
        ...state,
        isTransactionsLoading: false,
        successTransactions: true,
        transactions: action.payload.userData,
      }
    }
    case 'TRANSACTIONS_FAIL': {
      return {
        ...state,
        isTransactionsLoading: false,
        successTransactions: false,
        errorTransactions: 'failed to fetch transactions',
      }
    }
    case 'ORDERS': {
      return {
        ...state,
        isOrderLoading: true,
      }
    }
    case 'ORDERS_SUCCESS': {
      return {
        ...state,
        isOrdersLoading: false,
        successOrders: true,
        orders: action.payload,
      }
    }
    case 'ORDERS_FAIL': {
      return {
        ...state,
        isOrdersLoading: false,
        successOrders: false,
        errorOrders: 'failed to fetch orders',
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export const gameReducer = (state, action) => {
  switch (action.type) {
    case 'JOIN_COLOR': {
      return {
        ...state,
        isJoiningColor: true,
      }
    }
    case 'JOIN_COLOR_SUCCESS': {
      return {
        ...state,
        isJoiningColor: false,
        successJoinColor: true
      }
    }
    case 'JOIN_COLOR_FAIL': {
      return{
        ...state,
        errorJoinColor: 'unable to join game',
        isJoiningColor: false,
        successJoinColor: false,
      }
    }
    case 'JOIN_NUMBER': {
      return {
        ...state,
        isJoiningNumber: true,
      }
    }
    case 'JOIN_NUMBER_SUCCESS': {
      return {
        ...state,
        isJoiningNumber: false,
        successJoinNumber: true
      }
    }
    case 'JOIN_NUMBER_FAIL': {
      return{
        ...state,
        errorJoinNumber: 'unable to join game',
        isJoiningNumber: false,
        successJoinNumber: false,
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
} 

export const stateReducer = (state, action) => {
  switch (action.type) {
    case 'DETAILS': {
      return {
        ...state,
        isDetialsLoading: true,
        isLoading:false
      }
    }
    case 'DETAILS_FAIL': {
      return {
        ...state,
        errorDetails: 'problem in fetching details',
        isDetailsLoading: false,
        successDetials: false,
        balance: 0
      }
    }
    case 'DETAILS_SUCCESS': {
      return {
        ...state,
        isDetailsLoading: false,
        successDetails: true,
        balance: action.payload.balance,
        code: action.payload.code
      }
    }
    case 'LOGIN': {
      return {
        ...state,
        error: '',
        isLoading: true
      }
    }
    case 'LOGIN_ERROR': {
      return {
        ...state,
        error: 'Incorrect username or password!',
        isLoggedIn: false,
        isLoading: false
      }
    }
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isLoggedIn: false
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export const counterReducer = (state, action) => {
  switch (action.type) {
    case 'COUNT': {
      return {
        ...state,
        counter: action.payload
      }
    }
    case 'CURRENT_PERIOD': {
      return {
        ...state,
        isOrderLoading: true,
      }
    }
    case 'CURRENT_PERIOD_SUCCESS': {
      return {
        ...state,
        isPeriodLoading: false,
        successPeriod: true,
        period: action.payload.userData,
      }
    }
    case 'CURRENT_PERIOD_FAIL': {
      return {
        ...state,
        isPeriodLoading: false,
        successPeriod: false,
        errorPeriod: 'failed to fetch period',
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export const initGiftState = {
  isGiftEvent: false,
  successGiftEvent: false,
  errorGiftEvent: false,
  giftEvent: []
}

export const initResultState = {
  isAllResults: false,
  isUserResultsGold: false,
  isUserResultsPlatinum: false,
  isUserResultsDiamond: false,
  isUserResultsPalladium: false,
  successAllResults: false,
  successUserResultsGold: false,
  successUserResultsPlatinum: false,
  successUserResultsDiamond: false,
  successUserResultsPalladium: false,
  errorAllResults: '',
  errorUserResultsGold: '',
  errorUserResultsPlatinum: '',
  errorUserResultsDiamond: '',
  errorUserResultsPalladium: '',
  data: [],
  pageData: [],
  pageUserDataGold: [],
  pageUserDataPlatinum: [],
  pageUserDataDiamond: [],
  pageUserDataPalladium: [],
  userDataGold: [],
  userDataPlatinum: [],
  userDataDiamond: [],
  userDataPalladium: [],
}

export const initTranState = {
  isDepositeLoading: false,
  isWithdrawLoading: false,
  successDeposite: false,
  successWithdraw: false,
  errorDeposite: '',
  errorWithdraw: ''
}

export const initGameState = {
  isJoiningColor: false,
  isJoiningNumber: false,
  successJoinColor: false,
  successJoinNumber: false,
  errorJoinColor: '',
  errorJoinNumber: ''
}

export const initState = {
  error: false,
  success: false,
  errorDetails: false,
  successDetials: false,
  balance: 0,
  isLoading: false,
  isLoggedIn: false,
  isDetialsLoading: false,
  isDetailsLoading: false
}

const initCounterState = {
  counter: '0:00',
  period: '0000000000'
}
import { data } from 'jquery';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'reapop'
import { apiBaseUrl } from '../config';
import useGetDetails from '../hooks/useGetDetails';

const Userpass = (props) => {

  const [code, setCode] = useState('')
  const navigate = useNavigate()
  const { notify } = useNotifications()
  const [showPassword, setShowPassword] = useState(null);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(null);
  const [Password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [oldpassword,setoldpassword]= useState("");
  const [newpassword,setnewpassword]= useState("");
  const { getDetails } = useGetDetails()
  const [userdata , setUserdata] = useState(null)

  
  const api= async ()=>{
    const token = sessionStorage.getItem('logToken') //as string
    let url = apiBaseUrl + 'users/userDetails'
    const response = await fetch(url, {
      method: 'get',
      headers: {
         "Content-Type": "application/json",
         "Accept": 'application/json',
         "Authorization": token,
      }
    });
    const userResult = await response.json();
    const data = userResult
    setUserdata( data.userData.hasTransactionPin);
  }

  useEffect(()=>{
  api();
  },[])

  const oldregister = async () => {
    if(oldpassword !== "" && newpassword  !== ""){
          
      let passData = {
        oldPin:oldpassword,
        pin:newpassword
      };
      
      const token = sessionStorage.getItem('logToken')
      console.log(token);
      let url = apiBaseUrl + 'users/updatePin'
      let postPass = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(passData)
      });
      const updatepass = await postPass.json()
   
      notify(updatepass.message)
      
      navigate('/user-menu');
     
    }else{
      notify("Enter Pin");
    }
  }
  
 const handlenavigate = ()=>{
  navigate("/user-menu")
  }

  const register = async () => {
    if(Password === confirmPassword && Password !== "" ){
      let passData = {
        pin:Password
      };
      
      const token = sessionStorage.getItem('logToken')
      console.log(token);
      let url = apiBaseUrl + 'users/updatePin'
      let postPass = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(passData)
      });
      
      const updatenew = await postPass.json()
      console.log(postPass);
      notify(updatenew.message)
      handlenavigate();
      
    }else if(Password !== confirmPassword){
      notify("Pins Don't Match");
    }else {
      notify("Enter Pin")
    }   
  }

  return (
    <main>
      <div className="container pt-5" style={{ marginBottom: 106 }}>
        <div className="row">
          <div className=" col-sm-12">
            <div className="border-form">  
            {userdata ?  
            <div>
              <input 
              placeholder="Old Pin" 
              type={showPassword ? 'text' : 'password'}
              
              value={oldpassword} 
              className="formswith" 
              onChange={(e) => setoldpassword(e.target.value)}
              style={{ marginTop: "30px" }} 
              />
              <span onClick={() => setShowPassword(!showPassword)} className="eyeic"> { !showPassword ? <i className="fas fa-eye-slash"></i> : <i class="fa fa-eye"></i>} </span>  
              <input 
              placeholder="New Pin" 
              type={showPasswordConfirm ? 'text' : 'password'}
              value={newpassword} 
              className="formswith" 
              onChange={(e) => setnewpassword(e.target.value)}
              style={{ marginTop: "30px" }} 
              />
              <span onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} className="eyeic"> { !showPasswordConfirm ? <i className="fas fa-eye-slash"></i> : <i class="fa fa-eye"></i>} </span>    

              <div className="btn-main">
                <button className="btn btn-block" onClick={oldregister}>Update</button>
                
              </div>
              
            </div>   :
             <div>
             <input 
             placeholder="Enter Pin" 
             type={showPassword ? 'text' : 'password'}
             
             value={Password} 
             className="formswith" 
             onChange={(e) => setPassword(e.target.value)}
             style={{ marginTop: "30px" }} 
             />
             <span onClick={() => setShowPassword(!showPassword)} className="eyeic"> { !showPassword ? <i className="fas fa-eye-slash"></i> : <i class="fa fa-eye"></i>} </span>  
             <input 
             placeholder="confirm Pin" 
             type={showPasswordConfirm ? 'text' : 'password'}
             value={confirmPassword} 
             className="formswith" 
             onChange={(e) => setconfirmPassword(e.target.value)}
             style={{ marginTop: "30px" }} 
             />
             <span onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} className="eyeic"> { !showPasswordConfirm ? <i className="fas fa-eye-slash"></i> : <i class="fa fa-eye"></i>} </span>    
             
             <div className="btn-main">
                <button className="btn btn-block" onClick={register}>Update</button>
              </div>
           </div>  }
            <div>

            </div>
             
             
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Userpass

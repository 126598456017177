import { useEffect, useContext } from 'react'
import useGetPeriod from '../hooks/useGetPeriod'

import { CounterContext } from '../service/StateContext'
import useSocket from '../hooks/useSocket'

const Counter = () => {
  const { state, dispatch } = useContext(CounterContext)
  const { getCurrentPeriod } = useGetPeriod()
  const { socket } = useSocket()

  useEffect(() => {
    getCurrentPeriod()
  },[])

  useEffect(() => {
    socket.on('count', (data) => {
      dispatch({ type: 'COUNT', payload: data})
      localStorage.setItem('countdown', data)
    })
    if (state.counter === "2:59") {
      getCurrentPeriod()
    }
  }, [state.isLoggedIn,state.counter])

  return (
    <div className="countdown">
      <div className="countdown--period">
        <p className="periodText">Period</p>
        <p>
          <b className="periodDtata">{state.period}</b>
        </p>
      </div>
      <div className="countdown--timer">
        <p className="mb-2 periodText">Countdown</p>
        <span>
          <div className="timer">
            <p>
              <b className="periodDtata">{state.counter}</b>{' '}
            </p>
          </div>
        </span>
      </div>
    </div>
  )
}

export default Counter
import { useState } from 'react'
import { apiBaseUrl } from '../config'

const useGetRules = () => {

  const [rules, setRules] = useState('')
  const getRules = async () => {
    const token = sessionStorage.getItem('logToken')
    let url = apiBaseUrl + 'users/getRule'
    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      },
    })
    const getRulesData = await response.json()
    const data = getRulesData.rules
    setRules(data)
  }
  
  return { rules, getRules }
}

export default useGetRules
import { useContext } from 'react'
import socket from '../service/socket'
import { CounterContext }from '../service/StateContext'

const useGetPeriod = () => {

  const { dispatch } = useContext(CounterContext)

  const getCurrentPeriod = async () => {
    dispatch({ type: "CURRENT_PERIOD" })
    socket.emit('currentPeriod', {
      address: `${localStorage.getItem('logMetaAddress')}`,
    })
    socket.on('currentPeriod', (_data) => {
      if(_data.status === 'success') {
        localStorage.setItem('currentData', _data.userData)
        dispatch({ type: 'CURRENT_PERIOD_SUCCESS', payload: _data })
      }else{
        dispatch({ type: 'CURRENT_PERIOD_FAIL' })
      }
    })
  }
  

  return { getCurrentPeriod }
}

export default useGetPeriod
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'reapop'
import { apiBaseUrl } from '../config';

const ResetPin = (props) => {

    const [pin, setPin] = useState('')
    const navigate = useNavigate()
    const { notify } = useNotifications()
    const handlenavigate = ()=>{
        navigate("/user-menu")
    }

    const onResetPin = async () => {
        const token = sessionStorage.getItem('logToken')
        let resetData = {
            signature: localStorage.getItem('logSignature'),
            pin:pin
        };
        console.log(token);
        let url = apiBaseUrl + 'users/resetForgetPin'
        let resetPass = await fetch(url, {
            method: 'POST',
            headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            Authorization: token,
            },
            body: JSON.stringify(resetData)
        });
        
        const updatenew = await resetPass.json()
        console.log(resetPass);
        notify(updatenew.message)
        handlenavigate();  
    }  

  return (
    <main>
        <div className="container pt-5" style={{ marginBottom: 106 }}>
            <div className="row">
                <div className=" col-sm-12">
                    <div className="border-form">  
                        <input 
                            placeholder="Reset Pin" 
                            type={'text'}
                            value={pin} 
                            className="formswith" 
                            onChange={(e) => setPin(e.target.value)}
                            style={{ marginTop: "30px" }} 
                        />
                        <div className="btn-main">
                            <button className="btn btn-block" onClick={onResetPin}>Reset Pin</button>
                        </div>
                    <div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ResetPin

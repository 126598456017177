import { Link, Outlet } from 'react-router-dom'
import { FaMedal, FaUserAlt, FaGamepad } from 'react-icons/fa'

const NavBar = () => {
  return(
    <>
    <Outlet />
    <nav className="navigation">
      <ul>
        <li>
          <Link to="/home">
            <div className="text-center">
              <p style={{ fontSize: '28px' }}>
                <FaGamepad />
              </p>
              <p>Home</p>
            </div>
          </Link>
        </li>
        <li className="active">
          <Link to="/session-gold">
            <div className="text-center">
              <p style={{ fontSize: '20px' }}>
                <FaMedal />
              </p>
              <p>Session</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/user-menu">
            <div className="text-center">
              <p style={{ fontSize: '20px' }}>
                <FaUserAlt />
              </p>
              <p>Mine</p>
            </div>
          </Link>
        </li>
      </ul>
    </nav>
    </>
  )
}

export default NavBar
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'reapop'
import { apiBaseUrl, networkUrl } from '../config'
import { StateContext, TransactionsContext } from '../service/StateContext'
import useGetDetails from './useGetDetails'

const useDeposite = (amount) => {

  const { dispatch } = useContext(TransactionsContext)
  const { state } = useContext(StateContext)
  const { getDetails } = useGetDetails()
  const { notify } = useNotifications()
  const navigate = useNavigate()

  const deposite = async () => {
    dispatch({ type: 'DEPOSITE'})
    if (window.tronWeb != undefined) {
      const chainConnect = window.tronWeb.fullNode.host
      try {   
        const metaMaskAddress = await window.tronWeb.defaultAddress.base58
        let address = localStorage.getItem("logMetaAddress")
        // localStorage.setItem('metaAddress', metaMaskAddress)
        // localStorage.setItem("logChainConnect", chainConnect)

        console.log(metaMaskAddress,address,'metaMaskAddressmetaMaskAddress21763')
        if (metaMaskAddress == false) {
          dispatch({ type: "DEPOSITE_FAIL"})
          notify('Tron wallet not connected', 'error')
        }
        if(metaMaskAddress === address){
          if (metaMaskAddress != false) {
            let getAddress = localStorage.getItem('adAddress')
            let value = localStorage.getItem('amount')
            var tx = await window.tronWeb.trx.sendTransaction(getAddress, value * 10 ** 6)
            localStorage.setItem('hashTx', tx.txid)
            if(chainConnect === networkUrl){
              const data = {
                amount: localStorage.getItem('amount'),
                txhash: localStorage.getItem('hashTx'),
                networkid: `${localStorage.getItem("logChainConnect") === networkUrl ? '1' : '2'}`,
              }
              let url = apiBaseUrl + 'users/recharge'
              let result = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: sessionStorage.getItem('logToken'),
                },
                body: JSON.stringify(data),
              })
              result = await result.json()
              // console.log(result,'resultresultresult')
              if (result.status === 'success') {
                dispatch({ type: "DEPOSITE_SUCCESS" })
                notify(`Initiated adding ${amount} Trons, will update soon`, 'success')
                getDetails()
              } else if(result.message === 'token_not_found'){
                navigate('/',{replace: true})
                notify('Please Login again', 'error')
              }
              if (result.status === 'fail') {
                dispatch({ type: "DEPOSITE_FAIL"})
                notify(result.message, 'error')
              }
            }else{
              notify("Please change to mainnet." , 'error')
            }
          }
        }else{
            notify(
              'Invalid address, Please login again',
              'error'
            )
            navigate('/',{ replace: true})
            localStorage.clear()
            sessionStorage.clear()
          }
        } catch (e) {
        
        let error = e.split(',')?.[1]
        dispatch({ type: 'DEPOSITE_FAIL' })
        if(error === ' balance is not sufficient.' || error === ' no OwnerAccount.'){ 
          notify(
            'Bandwidth is low to continue the transaction, Please try after 24 hrs',
            'error'
          )
        }else{
          notify(
          'Currently transaction has been cancelled',
          'error'
        )}
      }

    } else {
      dispatch({ type: "DEPOSITE_FAIL"})
      notify('Tron wallet not connected or locked, please connect wallet and login', 'error')
      navigate('/',{replace: true})
    }
  }
  return { deposite }
}

export default useDeposite
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'reapop'
import { apiBaseUrl, networkUrl } from '../config'
const Register = (props) => {
  const { refCode } = props;
  const [code, setCode] = useState(refCode || '')
  const navigate = useNavigate()
  const { notify } = useNotifications()
  function goBackLogin() {
    navigate('/')
  }

  const register = async () => {
    const chainConnect = window.tronWeb.fullNode.host
    if (code.length !== 8){
      notify('Please enter a referal code to register for the game', 'error')
      return
    }
    if (window.tronWeb != undefined) {
      console.log("popup works");
      try {
        console.log("try");
        const metaMaskAddress = await window.tronWeb.defaultAddress.base58;
        localStorage.setItem("metaAddress", metaMaskAddress)
        localStorage.setItem("logChainConnect", chainConnect)
        console.log("address", metaMaskAddress);
        if (metaMaskAddress == false) {
          notify('Please Use TronLink wallet to register', 'error')
          return false;
        }
        if (metaMaskAddress != false) {
          console.log("signature");
          const addressData = metaMaskAddress;
          const hexMessage = await window.tronWeb.toHex(metaMaskAddress)
          console.log(hexMessage);
          // const signatureData = await window.tronWeb.trx.sign(hexMessage)
          // localStorage.setItem("signature", signatureData)
          // console.log(signatureData);
        }
      } catch (e) {
        //notify(`Internal error while making request ${e}, please try in sometime`, 'error')
      }
    }
    else {
      notify('Non-Tronlink browser detected. You should consider trying Tronlink Wallet!', 'error')
      localStorage.clear()
      navigate('/',{ replace: true})
    }
    let checkTronWallet = localStorage.getItem("logChainConnect") === networkUrl
    if(chainConnect === networkUrl){
      let data = {
        address: localStorage.getItem("metaAddress"),
        code: code,
        networkid: `${localStorage.getItem("logChainConnect") === networkUrl ? '1' : '2'}`
      };
      console.log(data,'datadata')

      let url = apiBaseUrl + 'users/register'
      let result = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        },
        body: JSON.stringify(data)
      });
      result = await result.json()
      if(result.status === "success"){
        notify('Welcome to Gagner-Games, Please authenticate and start Playing','success')
        localStorage.setItem('regToken', result.token)
        console.log(props);
        navigate('/', { replace: true })
      }
      if(result.message === "Referal code not valid") {
        notify('Please pick a valid referal code')
      }
      if(result.message === 'Please change to Shasta testnet'){
        notify('Please change to Shasta testnet')
      }
      if(result.message === "User Already registered") {
        //notify('User already registered, please login with authenticate or register with new wallet', 'error')
        navigate('/', { replace: true })
      }
    }else{
      notify("Please change to mainnet.", 'error')
    }
  }

  return (
    <main>
      <div className="container pt-5" style={{ marginBottom: 106 }}>
        <div className="row">
          <div className=" col-sm-12">
            <div className="border-form">
              <h4 className="reg-text">New Member</h4>
              <label className="label-text">Enter Referral Code</label>
              <input className="forms" onChange={(e) => setCode(e.target.value)} value={refCode && refCode}/>
              <div className="btn-main">
                <button className="btn btn-block" onClick={register}>Register</button>
              </div>
              <div className="btn-main">
                <button className="btn btn-block" onClick={goBackLogin}>Existing Member Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Register

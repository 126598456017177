import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import NotificationsSystem,{ useNotifications, setUpNotifications, bootstrapTheme } from 'reapop';
import useGetDetails from '../hooks/useGetDetails';
import image from '../img/logo.png';
import { CounterContext, StateContext } from '../service/StateContext';
import RulesModal from './RulesModal';
import useGetRules from '../hooks/useGetRules';
import useGetNotification from "../hooks/useGetNotification"
import BellIcon from 'react-bell-icon'
import { Modal } from 'react-bootstrap'; 
import ReactHtmlParser from 'react-html-parser'
import socket from '../service/socket';

const Header = () => {

  setUpNotifications({
    defaultProps: {
        position: 'top-center',
        dismissible: true,
        dismissAfter: 2000
    } 
  })

  const [showRulesModal, setShowRulesModal] = useState(false)
  const { state } = useContext(StateContext)
  const { state: counterState } = useContext(CounterContext)
  const navigate = useNavigate()
  const { notifications, dismissNotification } = useNotifications()
  const { rules, getRules } = useGetRules()
  const [rulesEmit, setRulesEmit] = useState("");
  const { state: userState }  = useContext(StateContext)

  const { notification, getNotification } = useGetNotification()
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const { getDetails } = useGetDetails()
  const depositHandler = () => {
    navigate('/deposite')
  }

  const withdrawHandler = () => {
    navigate('/withdraw')
  }
  
  useEffect(() => {
    setInterval(() => {
      getDetails()
    }, 5000)
    // getDetails()
    //socket.emit("userTransactions", { "address": localStorage.getItem("logMetaAddress") });
  },[state.isLoggedIn]) 

  useEffect(() => {
    if (counterState.counter === "2:59"){
      getDetails()
    }
  }, [counterState.counter])


  useEffect(() => {
    socket.on("GetNotification", (arg) => {
      setRulesEmit(arg.notification) // world
    });
  }, [])
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <>
    <NotificationsSystem
      notifications={notifications}
      dismissNotification={(id) => dismissNotification(id)}
      theme={bootstrapTheme}
    />
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={image} className="img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            {sessionStorage.getItem("isLoggedIn") && 
              <div className="headerText">
              <p>Available Balance: <span>{userState.balance}  </span></p>
              <BellIcon width='40' onClick={handleShow} cursor="pointer" color="gold" active={true} animate={true} />
              <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title> <p style={{ fontSize: "1.5rem", fontWeight: "700" }}>Notification</p></Modal.Title>
              </Modal.Header>
                <Modal.Body><p style={{ fontSize: "1rem", fontWeight: "700" }}>{ReactHtmlParser(rulesEmit)}</p></Modal.Body>
              <Modal.Footer>
              <button variant="secondary" className="btn-block" onClick={handleClose}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
          <a className="btn btn-primary text-white rounded-0 mr-2" onClick={depositHandler}>
            Add Tron
          </a>    
          <a className="btn btn-violet text-white rounded-0" onClick={() => setShowRulesModal(true)}>
            Read Rules
          </a>
          <a className="btn btn-with text-white rounded-0" onClick={withdrawHandler}>
            Withdraw
          </a>
        </div>
      }
    </div>
  </div>
    <RulesModal 
      showModal={showRulesModal}
      setShowModal={setShowRulesModal}
      />     
    </div>
  </header>
   </>
  )
}

export default Header
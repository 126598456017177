import { useState, useEffect, useContext } from 'react'
import useGetResults from '../hooks/useGetResults'
import { CounterContext, ResultContext } from '../service/StateContext'

const UserResults = ({
  gameTable
}) => {

  const { 
    getAllUserResults,
    getUserGoldResults,
    getUserPlatinumResults,
    getUserDiamondResults,
    getUserPalladiumResults,
  } = useGetResults()
  const { state } = useContext(ResultContext)
  const { state: counterState } = useContext(CounterContext)
  const [goldPage, setGoldPage] = useState(1)
  const [platinumPage, setPlatinumPage] = useState(1)
  const [diamondPage, setDiamondPage] = useState(1)
  const [palladiumPage, setPalladiumPage] = useState(1)

  useEffect(() => {
    getAllUserResults(1)
  }, [getAllUserResults])

  useEffect(() => {
    if (counterState.counter === "2:59") {
      getAllUserResults(1)
    }
  }, [counterState.counter, getAllUserResults])

  useEffect(() => {
    if (gameTable === 'GOLD') {
      getUserGoldResults(goldPage)
    } 
    else if (gameTable === 'PLATINUM') {
      getUserPlatinumResults(platinumPage)
    }
    else if (gameTable === 'DIAMOND') {
      getUserDiamondResults(diamondPage)
    }
    else if (gameTable === 'PALLADIUM') {
      getUserPalladiumResults(palladiumPage)
    }
  }, [
    gameTable,
    goldPage, 
    platinumPage, 
    diamondPage, 
    palladiumPage,
    getUserGoldResults,
    getUserDiamondResults,
    getUserPlatinumResults,
    getUserPalladiumResults
    ])

  const nextUserHandler = async () => {
    if (gameTable === 'GOLD') {
      setGoldPage(goldPage + 1)
    } 
    else if (gameTable === 'PLATINUM') {
      setPlatinumPage(platinumPage + 1)
    }
    else if (gameTable === 'DIAMOND') {
      setDiamondPage(diamondPage + 1)
    }
    else if (gameTable === 'PALLADIUM') {
      setPalladiumPage(palladiumPage + 1)
    }
  }

  const prevUserHandler = async (game) => {
    if (gameTable === 'GOLD') {
      setGoldPage(goldPage - 1)
    } 
    else if (gameTable === 'PLATINUM') {
      setPlatinumPage(platinumPage - 1)
    }
    else if (gameTable === 'DIAMOND') {
      setDiamondPage(diamondPage - 1)
    }
    else if (gameTable === 'PALLADIUM') {
      setPalladiumPage(palladiumPage - 1)
    }
  }

  return (
    <>
    <h4 className="text-center top">My {gameTable.toLowerCase()} Record</h4>
    <div className="table-responsive resultTable">
      <table className="table">
        <thead className="tran-head">
          <tr>
            <th>Period</th>
            <th>No of Trons</th>
            <th>Joined Game</th>
            <th>Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {gameTable === "GOLD" &&
            (state.userDataGold === undefined || state.userDataGold === null || state.userDataGold.length <= 0
              ? 
              <tr>
                <td>No Records Available</td>
              </tr>
              : 
              state.userDataGold.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div>{item.period}</div>
                      </td>
                      <td>
                        <div>{item.amount}</div>
                      </td>
                      <td>
                        <div>
                          {item.number}
                          {item.colour === 'green' ? (
                            <div className="result-circle btn-green"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'purple' ? (
                            <div className="result-circle btn-violet"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'red' ? (
                            <div className="result-circle btn-red"></div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>{item.price}</div>
                      </td>
                      <td>
                        <div>{item.status}</div>
                      </td>
                    </tr>
                  )
                }
              )
            )
          }
          {gameTable === "PLATINUM" &&
            (state.userDataPlatinum === undefined || state.userDataPlatinum === null || state.userDataPlatinum.length <= 0
              ? <h6>No Records Available</h6>
              : state.userDataPlatinum.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div>{item.period}</div>
                      </td>
                      <td>
                        <div>{item.amount}</div>
                      </td>
                      <td>
                        <div>
                          {item.number}
                          {item.colour === 'green' ? (
                            <div className="result-circle btn-green"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'purple' ? (
                            <div className="result-circle btn-violet"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'red' ? (
                            <div className="result-circle btn-red"></div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>{item.price}</div>
                      </td>
                      <td>
                        <div>{item.status}</div>
                      </td>
                    </tr>
                  )
                }
              )
            )
          }
          {gameTable === "DIAMOND" &&
            (state.userDataDiamond === undefined || state.userDataDiamond === null || state.userDataDiamond.length <= 0
              ? <h6>No Records Available</h6>
              : state.userDataDiamond.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div>{item.period}</div>
                      </td>
                      <td>
                        <div>{item.amount}</div>
                      </td>
                      <td>
                        <div>
                          {item.number}
                          {item.colour === 'green' ? (
                            <div className="result-circle btn-green"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'purple' ? (
                            <div className="result-circle btn-violet"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'red' ? (
                            <div className="result-circle btn-red"></div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>{item.price}</div>
                      </td>
                      <td>
                        <div>{item.status}</div>
                      </td>
                    </tr>
                  )
                }
              )
            )
          }
          {gameTable === "PALLADIUM" &&
            (state.userDataPalladium === undefined || state.userDataPalladium === null || state.userDataPalladium.length <= 0
              ? <h6>No Records Available</h6>
              : state.userDataPalladium.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div>{item.period}</div>
                      </td>
                      <td>
                        <div>{item.amount}</div>
                      </td>
                      <td>
                        <div>
                          {item.number}
                          {item.colour === 'green' ? (
                            <div className="result-circle btn-green"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'purple' ? (
                            <div className="result-circle btn-violet"></div>
                          ) : (
                            <div></div>
                          )}
                          {item.colour === 'red' ? (
                            <div className="result-circle btn-red"></div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>{item.price}</div>
                      </td>
                      <td>
                        <div>{item.status}</div>
                      </td>
                    </tr>
                  )
                }
              )
            )
          }
        </tbody>
      </table>
    </div>
    {gameTable === "GOLD" &&
      (Number.isNaN(state.pageUserDataGold.limit * state.pageData.prevPage + 1) ? (
        <></>
      ) : (
        <div className="user-prev-next">
          <div className="total-page">
            {state.pageUserDataGold.limit * state.pageUserDataGold.prevPage + 1} -{' '}
            {state.pageUserDataGold.limit * state.pageUserDataGold.page}
          </div>
          <div className="total-page">{state.pageUserDataGold.totalDocs}</div>
          <div>
            {state.pageUserDataGold.hasPrevPage === false ? (
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                disabled
                style={{ fontSize: '22px', color: '#cec7c7' }}
              ></i>
            ) : (
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                onClick={prevUserHandler}
                style={{ fontSize: '22px', cursor: 'pointer' }}
              ></i>
            )}
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            {state.pageUserDataGold.hasNextPage === true ? (
              <i
                className="fa fa-angle-right"
                aria-hidden="true"
                onClick={nextUserHandler}
                style={{ fontSize: '22px', cursor: 'pointer' }}
              >
                {' '}
              </i>
            ) : (
              <i
                className="fa fa-angle-right"
                aria-hidden="false"
                disabled
                style={{ fontSize: '22px', color: '#cec7c7' }}
              ></i>
            )}
          </div>
        </div>
      ))}
    {gameTable === "PLATINUM" &&
      (Number.isNaN(state.pageUserDataPlatinum.limit * state.pageData.prevPage + 1) ? (
        <></>
      ) : (
        <div className="user-prev-next">
          <div className="total-page">
            {state.pageUserDataPlatinum.limit * state.pageUserDataPlatinum.prevPage + 1} -{' '}
            {state.pageUserDataPlatinum.limit * state.pageUserDataPlatinum.page}
          </div>
          <div className="total-page">{state.pageUserDataPlatinum.totalDocs}</div>
          <div>
            {state.pageUserDataPlatinum.hasPrevPage === false ? (
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                disabled
                style={{ fontSize: '22px', color: '#cec7c7' }}
              ></i>
            ) : (
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                onClick={prevUserHandler}
                style={{ fontSize: '22px', cursor: 'pointer' }}
              ></i>
            )}
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            {state.pageUserDataPlatinum.hasNextPage === true ? (
              <i
                className="fa fa-angle-right"
                aria-hidden="true"
                onClick={() => {
                  nextUserHandler()
                }}
                style={{ fontSize: '22px', cursor: 'pointer' }}
              >
                {' '}
              </i>
            ) : (
              <i
                className="fa fa-angle-right"
                aria-hidden="false"
                disabled
                style={{ fontSize: '22px', color: '#cec7c7' }}
              ></i>
            )}
          </div>
        </div>
      ))}
    {gameTable === "DIAMOND" &&
      (Number.isNaN(state.pageUserDataDiamond.limit * state.pageData.prevPage + 1) ? (
        <></>
      ) : (
        <div className="">
          <div className="user-prev-next">
            <div className="total-page">
              {state.pageUserDataDiamond.limit * state.pageUserDataDiamond.prevPage + 1} -{' '}
              {state.pageUserDataDiamond.limit * state.pageUserDataDiamond.page}
            </div>
            <div className="total-page">{state.pageUserDataDiamond.totalDocs}</div>
            <div>
              {state.pageUserDataDiamond.hasPrevPage === false ? (
                <i
                  className="fa fa-angle-left"
                  aria-hidden="true"
                  disabled
                  style={{ fontSize: '22px', color: '#cec7c7' }}
                ></i>
              ) : (
                <i
                  className="fa fa-angle-left"
                  aria-hidden="true"
                  onClick={() => {
                    prevUserHandler()
                  }}
                  style={{ fontSize: '22px', cursor: 'pointer' }}
                ></i>
              )}
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              {state.pageUserDataDiamond.hasNextPage === true ? (
                <i
                  className="fa fa-angle-right"
                  aria-hidden="true"
                  onClick={() => {
                    nextUserHandler()
                  }}
                  style={{ fontSize: '22px', cursor: 'pointer' }}
                >
                  {' '}
                </i>
              ) : (
                <i
                  className="fa fa-angle-right"
                  aria-hidden="false"
                  disabled
                  style={{ fontSize: '22px', color: '#cec7c7' }}
                ></i>
              )}
            </div>
          </div>
        </div>
      ))}
    {gameTable === "PALLADIUM" &&
      (Number.isNaN(state.pageUserDataPalladium.limit * state.pageData.prevPage + 1) ? (
        <></>
      ) : (
        <div className="user-prev-next">
          <div className="total-page">
            {state.pageUserDataPalladium.limit * state.pageUserDataPalladium.prevPage + 1} -{' '}
            {state.pageUserDataPalladium.limit * state.pageUserDataPalladium.page}
          </div>
          <div className="total-page">{state.pageUserDataPalladium.totalDocs}</div>
          <div>
            {state.pageUserDataPalladium.hasPrevPage === false ? (
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                disabled
                style={{ fontSize: '22px', color: '#cec7c7' }}
              ></i>
            ) : (
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                onClick={() => {
                  prevUserHandler()
                }}
                style={{ fontSize: '22px', cursor: 'pointer' }}
              ></i>
            )}
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            {state.pageUserDataPalladium.hasNextPage === true ? (
              <i
                className="fa fa-angle-right"
                aria-hidden="true"
                onClick={() => {
                  nextUserHandler()
                }}
                style={{ fontSize: '22px', cursor: 'pointer' }}
              >
                {' '}
              </i>
            ) : (
              <i
                className="fa fa-angle-right"
                aria-hidden="false"
                disabled
                style={{ fontSize: '22px', color: '#cec7c7' }}
              ></i>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default UserResults